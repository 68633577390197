.ra {
  &__myaccount{
    background-color: var(--ra-color-offwhite1);
    &-headline {
      font-size: 1.125rem;
      @extend .fw-600;
      color: var(--ra-color-dark);
      margin: 1.5rem 0 1.125rem;
    }
    // change Password
    &-security{
      background-color: var(--ra-color-white);
      text-align: center;
      padding: 1rem 1.5rem 2rem;
      border-radius: 0.5rem;
    }
    &-popup {
      margin-top: 0.5rem;
    }
    &-version{
      margin-top: 1rem;
      text-align: right;
    }
    &-bankinfo {
      background-color: var(--ra-color-white); 
      padding: 1.5rem;
      border-radius: 0.5rem;
    }
  }
  // Profile 
  &__profile {
    padding: 1.5rem;
    text-align: center;
    border-radius: 0.5rem !important;
    background-color: var(--ra-color-white) !important;

    &-btngroup {
      margin-top: 1rem;
    }

    &-name {
      font-size: 1.5rem;
      @extend .fw-600;
      color: var(--ra-color-dark);
    }

    &-email span{
      font-size: 1.125rem;
      @extend .fw-500;
      color: var(--ra-color-dark);
      word-break: break-word !important;
    }

    &-field {
      font-size: 1rem;
      color: var(--ra-color-dark);
      word-break: break-word !important;
      margin-bottom: 0.5rem !important;

      &--icon {
        margin-right: 0.5rem;
      }
    }

    &-img {
      margin: 0 auto 1rem;
      border-radius: 50%;
    }

    &-desc {
      margin-bottom: 1rem !important;
      &--data {
        @extend .fw-500;
      }
      &--title {
        margin-bottom: 0 !important;
      }
      .v-text-field {
        padding-top: 0 !important;
        margin-top: 0 !important;
      }
    }
  }
  // Account DangerZone
  &__dangerzone {
    color: $color-dark !important;
    padding: 1.5rem;
    background-color: $color-bgred;
    border-radius: 0.5rem;

    &-headline {
      @include respondMin(md) {
        margin: 0 !important;
      }
    }
  }
  // Login Logs
  &__login{
    &-btngroup{
      margin-left: 1rem;
    }
    &-activity{
      padding: 1.5rem;
      border: 1px solid $color-offgrey;
      border-radius: 0.5rem;
    }
  }
  &__mysales{
    @extend .ra__myaccount;
    &-headline {
      @extend .ra__myaccount-headline;
    }
  }
  &__btngroup{
    margin-top: 1rem;
  }
}
.settings-card .card-body {
  padding: 0;
}