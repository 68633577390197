.poster{
  &__event{
    &-box{
      margin: 1.5rem 0;
      @include respondMax(md){
        margin-bottom: 1rem;
      }
    }
    &-imagebox{
        margin-bottom: 1.5rem;
        .v-image{
          border-radius: 0.5rem;
        }
    }
    &-card{
      background-color: var(--ra-lobby-schedule_container_colour) !important;
      margin-bottom: 1rem;
    }
    &-cardimg{
      .v-image{
        border-radius: 0.5rem;
      }
    }
    &-desc{
      font-size: 1rem;
      color: var(--ra-color-grey);
      ol, ul {
        padding-left: 0 !important;
      }
    }
    &-mediacard{
      background-color: var(--ra-lobby-schedule_container_colour) !important;
      margin-bottom: 1rem;    
    }
    &-mediaimg{
      .v-image{
        border-radius: 0.5rem;
      }
    }
    &-cardbody{
      .v-divider{
        border-color: var(--ra-color-offgrey) !important;
      }
    }
    &-mediabody{
      padding: 1rem 1rem 0 1rem;
    }
    &-mediaitem{
      background-color: var(--ra-lobby-schedule_container_colour) !important;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
    }
    &-mediainfo{
      padding: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .media__info{
        &-name{
          font-size: 1rem;
          margin-bottom: 0.25rem;
          @extend .fw-500;
        }
        &-size{
          margin-bottom: 0 !important;
          font-size: 0.875rem;
          color: var(--ra-color-grey);
        }
      }
    }
    &-subtitle{
      font-size: 1.5rem;
      margin: 1.5rem 0;
      @extend .fw-600;
      color: var(--ra-color-dark);
    }
    &-cardbody{
      padding: 1rem;
    }
    &-cardtitle{
      font-size: 1.25rem;
      @extend .fw-500;
      line-height: 1.5;
      margin-bottom: 0.2rem;
    }
    &-carddesc{
      margin-bottom: 0 !important;
      font-size: 1rem;
      color: var(--ra-color-grey);
      span{
        &:first-child{
          padding-right: 1rem;
        }
      }
    }

    &-modal{
      &title{
        font-size: 1.2rem;
        margin: 1.5rem 0;
        @extend .fw-600;
        color: var(--ra-color-dark);
      }
      &desc{
        font-size: 1rem;
        color: var(--ra-color-grey);
      } 
    }
    &-modalbody{
      margin-bottom: 1.5rem;
    }
    
    &-modalcarditem{
      margin-bottom: 1rem;
    }
    
    &-modalinfo{
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .modal__info{
        &-modalname{
          font-size: 1.125rem;
          margin-bottom: 0.5rem;
          @extend .fw-500;
          color: var(--ra-color-dark);
        }
        &-modalsize{
          margin-bottom: 0 !important;
          font-size: 1rem;
          color: var(--ra-color-grey);
        }
      }
    }
    &-nodata{
      width: 100%;
      height: auto;
      padding: 1.5rem;
      border: 1px solid #c8c8c8;
      border-radius: 0.5rem;
      margin: 30px 0px;
      text-align: center;

      span.cross-icon{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        line-height: 50px;
        background-color: red;
        margin: 20px auto;
        justify-content: center;
      }
    }
    &-modalimg {
      .media-display__iframe-border {
        min-height: 640px !important;
        
        @include respondMax(md) {
          min-height: 450px !important;
        }
        @include respondMax(sm) {
          min-height: 100px !important;
        }        
      }
    }
  }
}