.accordion {
  &__divider {
    margin: 0.75rem 0 !important;
  }

  &__menu {
    text-align: right;
  }

  &__title {
    @extend .fw-600;

    &-poll {
      margin-top: 0.5rem;
    }
  }

  &__list {
    margin-left: 0.5rem;
    width: 100%;

    &-reset {
      margin-left: 0 !important;
    }

    &-announcement {
      padding: 2rem;
    }

    &-title {
      font-size: 1rem;
      color: var(--ra-lobby-main_font_colour);
      margin-bottom: 0.25rem !important;
      line-height: normal !important;
    }

    &-desc {
      font-size: 0.8rem;
      color: var(--ra-lobby-main_font_colour);
      margin: 0.25rem 0 !important;
    }

    &-session {
      margin-top: 0.5rem;
    }

    &-content {
      width: 100%;
    }

    &-img {
      &--profile {
        margin-left: 0.75rem;
      }
    }
  }

  &__poll-btn {
    margin-top: 1.2rem;
    text-align: center;
  }

  &__sponsor {
    margin-bottom: 1rem;
    .v-image {
      border-radius: 0.25rem;
      max-height: 128px;
    }
  }

  // .mdi:before, .mdi-set{
  //    color: $color-dark !important;
  // }
}

.media-display__profile-icon {
  background-color: $color-offprimary;
  border-radius: 50%;
}

.networking-chat {
  height: 100%;
  max-height: 100vh;
  overflow: auto;
}

.chat {
  max-height: 420px;
  overflow: auto;
  &__box {
    margin-top: 0.5rem;
  }

  &__text {
    .v-label {
      top: calc(50% - 9px) !important;
    }

    .v-text-field__details {
      display: none !important;
    }
  }

  &__profile {
    &-1 {
      margin-right: 0.35rem;
    }

    &-2 {
      margin: 0 0.35rem 0 0.35rem;
    }
  }

  &__name {
    font-size: 0.9rem;
    @extend .fw-500;
    margin: 0.2rem !important;

    &-time {
      font-size: 0.8rem;
      @extend .fw-400;
      color: $color-grey;
    }

    &-2 {
      text-align: right;
    }
  }

  &__send {
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;

    .v-label,
    .v-input__control {
      font-size: 0.8rem;
    }

    .v-input__slot {
      background: var(--ra-color-offwhite1) !important;
      box-shadow: none !important;
      border: 1px solid $color-offwhite;
      margin-bottom: 0 !important;
    }
    &-wrapper {
      margin-left: 0.5rem;
    }
    &-btn {
      color: $color-primary !important;
    }
  }

  &__title {
    font-size: 1rem;
    @extend .fw-500;
    margin: 0;
  }

  &__head {
    padding-bottom: 0.25rem;
    &-divider {
      margin: 0 !important;
    }
  }

  &__message {
    padding: 0.3rem 0.5rem;
    max-width: 90%;
    position: relative;
    display: inline-block;

    &-1 {
      background-color: $color-offwhite;
      border-radius: 0 0.5rem 0.5rem 0.5rem;
    }

    &-2 {
      background-color: $color-offprimary;
      border-radius: 0.5rem 0 0.5rem 0.5rem;
      margin-left: auto;
    }

    &-text {
      font-size: 0.8rem;
      color: $color-dark;
      margin-bottom: 0px !important;
    }

    &-icon {
      position: absolute;
      top: 0px;

      &.chat-1 {
        right: -28px;
      }

      &.chat-2 {
        left: -24px;
      }

      &--delete {
        color: $color-offpink !important;
        margin-right: 0.25rem;
        cursor: pointer;
      }

      &--thumb {
        color: $color-primary !important;
        cursor: pointer;
      }
    }
  }

  &__mobile {
    padding: 0 1rem;
    text-align: left;

    &-sheet {
      background-color: var(--ra-color-white) !important;
    }
  }
}

.schedule {
  &__EventPlayer {
    color: $color-white !important;
    border: none !important;
    background-color: rgba($color: $color-rs, $alpha: 1) !important;
    @extend .fw-500;
  }

  &__EventExpo {
    color: $color-white !important;
    border: none !important;
    background-color: rgba($color: $color-iframe, $alpha: 1) !important;
    @extend .fw-500;
  }

  &__EventPoster {
    color: $color-white !important;
    border: none !important;
    background-color: rgba($color: $color-rtmp, $alpha: 1) !important;
    @extend .fw-500;
  }
  &__EventNetworkingPlayer {
    @extend .fw-500;
    color: $color-white !important;
    border: none !important;
    background-color: rgba($color: $color-html, $alpha: 1) !important;
  }
  &__room {
    &-0 {
      @extend .fw-500;
      border: none !important;
      color: $color-white !important;
      background-color: rgba($color: $color-html, $alpha: 1) !important;
    }
    &-1 {
      @extend .fw-500;
      border: none !important;
      color: $color-white !important;
      background-color: rgba($color: $color-rs, $alpha: 1) !important;
    }
    &-2 {
      @extend .fw-500;
      border: none !important;
      color: $color-white !important;
      background-color: rgba($color: $color-rtmp, $alpha: 1) !important;
    }
    &-3 {
      @extend .fw-500;
      border: none !important;
      color: $color-white !important;
      background-color: rgba($color: $color-es, $alpha: 1) !important;
    }
    &-4 {
      @extend .fw-500;
      border: none !important;
      color: $color-white !important;
      background-color: rgba($color: $color-iframe, $alpha: 1) !important;
    }
    &-5 {
      @extend .fw-500;
      border: none !important;
      color: $color-white !important;
      background-color: rgba($color: $color-prv, $alpha: 1) !important;
    }
    &-6 {
      @extend .fw-500;
      border: none !important;
      color: $color-white !important;
      background-color: rgba($color: $color-red, $alpha: 1) !important;
    }
  }
}

.lobby {
  &__video-img {
    margin-bottom: 1.5rem;
  }
  &__date {
    font-size: 1.125rem;
  }
  &__media {
    .v-image {
      border-radius: 0.5rem;
    }
  }
  &__bg {
    background-image: var(--ra-lobby-background_image);
    background-position: top;
    background-attachment: fixed;
    background-size: cover;
    &-body {
      min-height: 100vh;
    }
    .content__box-title, .lobby__bg-text {
      color: var(--ra-lobby-main_font_colour) !important;
      .mdi-calendar-month-outline, .mdi-clock-outline {
        color: var(--ra-lobby-main_font_colour) !important;
      }
    }
    .icon-anim, .mdi-close, .mdi-trash-can, .mdi-emoticon-outline {
      color: var(--ra-lobby-main_font_colour) !important;
    }
    .ra__input .v-input__slot {
      background-color: var(--ra-lobby-schedule_container_colour) !important;
    }
    .ra__fields-label, .ql-editor, .ql-snow .ql-picker, .v-label {
      color: var(--ra-lobby-main_font_colour) !important;
    }
    .quillWrapper .ql-snow .ql-stroke {
      stroke: var(--ra-lobby-main_font_colour) !important;
    }
  }
}
.v-dialog {
  .lobby__bg {
    background-attachment: unset !important;
    background-size: cover !important;
  }
}
.sponsor__img {
  .v-responsive__content {
    width: 100% !important;
  }
}
.attendees {
  &__box {
    padding: 0.25rem 0;
    .accordion {
      &__list {
        flex: auto;
        &-title {
          cursor: pointer;
        }
      }
    }
  }
  &__invite {
    display: flex !important;
    align-items: center !important;
    cursor: pointer !important;
    .v-icon {
      padding-right: 0.5rem;
      font-size: 1rem !important;
    }
  }
  &__modal {
    &-menu {
      padding: 0 0.5rem !important;
      .v-application--is-ltr .v-list-item__action:first-child {
        margin-right: 24px !important;
      }
      .accordion__divider {
        margin: 0.25rem 0 !important;
      }
      .v-list-item {
        min-height: 32px !important;
        .v-list-item__action {
          margin-top: 6px !important;
          margin-bottom: 6px !important;
        }
      }
    }
    &-maintitle {
      margin-bottom: 0.5rem !important;
      color: var(--ra-color-dark) !important;
      @extend .fw-600;
    }
    &-desc {
      max-width: 400px;
      color: var(--ra-color-grey) !important;
      white-space: normal !important;
      line-height: 1.5 !important;
    }
  }
}

.fullscreen {
  &__box {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    transition: 1s ease-in-out;
    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: var(--ra-color-primary);
      .v-icon {
        color: $color-white !important;
      }
    }
    @include respondMax(md) {
      display: none;
    }
  }
}

// Session stage button group
.session-custom-btn {
  max-width: 350px !important;
  margin: 0.5rem;
  .v-btn__content {
    display: block !important;
    width: 100%;
    white-space: normal;
    line-height: 1.5;
  }
}

// Pinned Session SCSS
.pinned {
  &__session {
    background-color: var(--ra-lobby-schedule_container_colour);
    padding: 0.5rem;
    border-radius: 0.5rem;
    margin-bottom: 0.5rem;
    &:hover {
      cursor: pointer;
    }
    .v-image {
      width: 120px;
      border-radius: 0.5rem;
    }
    .accordion {
      &__list {
        overflow: hidden;
        &-title {
          @extend .fw-600;
          word-break: break-word;
          font-size: 0.975rem;
        }
        &-desc, &-desc > p {
          overflow: hidden;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          max-height: 38px;
        }
      }
    }
  }
}
.announcements {
  .accordion {
    &__list {
      &-box {
        display: flex;
        align-items: flex-start;
      }
      &-img {
        margin-right: 1rem;
        .v-image {
          width: 40px !important;
          height: 40px !important;
          min-width: 40px !important;
          max-width: 40px !important;
        }
      }
      &-head {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2 {
          font-size: 1rem;
          @extend .fw-500;
          color: var(--ra-lobby-main_font_colour);

          cursor: pointer;
          span {
            display: block;
            font-size: 0.8rem;
            color: var(--ra-lobby-main_font_colour) !important;
            font-weight: normal;
            cursor: pointer;
          }
        }
        .v-icon {
          font-size: 1.5rem;
        }
      }
      &-desc {
        color: var(--ra-lobby-main_font_colour) !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 0.875rem;
        cursor: pointer;

        p {
          margin-bottom: 0 !important;
        }
      }
    }
  }
  &__card {
    &-body {
      padding: 1.5rem;
    }
  }
}

.rightsidebar {
  &__height {
    max-height: 525px !important;
    overflow: hidden;
  }
}

// Lobby Chat
.rachat {
  &__chat-header {
    padding: 0 !important;
  }
  &__p2p {
    color: #6a6a6a;
  }
  &__session-player {
    color: #f44336;
  }
  &__event-chat {
    color: #1976d2;
  }
  &__bold {
    @extend .fw-500;
  }
  &__head {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    border-radius: 0.5rem;
    &:hover {
      background-color: var(--ra-color-offwhite);
    }
    .accordion__list-title {
      .rachat__session-player {
        @extend .fw-500;
      }
      span {
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        max-height: 17px;
      }
    }
  }
  &__my-message {
    justify-content: flex-end;
  }
  &__message {
    &-right {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
    &-left {
      width: 100%;
    }
  }
}

// Test modal
.test {
  &__modal {
    .v-card {
      padding: 1.5rem;
    }
    .v-stepper__content {
      padding: 0 !important;
    }
    &-title {
      font-size: 1.25rem;
      @extend .fw-600;
      color: var(--ra-color-dark);
      margin-bottom: 1rem !important;
    }
    &-img {
      border-radius: 0.5rem;
    }
    &-audio {
      position: relative;
    }
    &-audiobar {
      position: absolute;
      bottom: 1rem;
      right: 1rem;
    }
    &-chatimg {
      max-width: 400px !important;
      margin: auto;
    }
    &-message {
      margin: 0.25rem 0 1.5rem;
      text-align: center;
      &--img {
        width: 48px;
        height: 48px;
        margin: 0.5rem auto;
      }
    }
    &-btn {
      &--spacing {
        margin-right: 0.5rem;
      }
    }
    &-process {
      margin-left: 0.5rem;
      color: var(--ra-color-grey);
      font-style: italic;
    }
    &-summary {
      background-color: var(--ra-lobby-schedule_container_colour);
      padding: 1rem;
      border-radius: 0.5rem;
      margin: 1rem 0;
      &--img {
        width: 32px;
        height: 32px;
      }
    }
  }
}
