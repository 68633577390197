.businesscard {
  &__bg {
    background-color: var(--ra-color-offwhite1);
  }
  &__white{
     background-color: var(--ra-color-white);
     padding: 1.5rem;
     border-radius: 0.5rem;
  }
  &__spacing {
    margin-bottom: 1.5rem;

    @include respondMin(sm) {
      margin-bottom: 0;
    }
  }

  &__head {
    font-size: 1.5rem;
    @extend .fw-600;
    margin-bottom: 0.5rem;
    @include respondMax(sm){
      font-size: 1.4rem;
    }

    &-desc {
      font-size: 1.15rem;
      color: var(--ra-color-grey);
      margin-bottom: 0 !important;
    }
  }

  &__tab {
    margin-top: 1rem;

    &-title {
      font-size: 1.25rem;
      @extend .fw-600;
    }
  }

  &__main {
    margin: 1.5rem 0;

    &-bg{ 
        background-color: var(--ra-color-white);
        padding: 1.5rem;
        text-align: center;
        border-radius: 0.5rem !important;

        &--title{
            margin-bottom: 1.5rem;
        }
    }

    &-btngroup--modal{
        margin-top: 1rem;
    }

    &-title {
      font-size: 1.5rem;
      @extend .fw-600;
      color: var(--ra-color-dark);
      @include respondMax(sm){
        font-size: 1.375rem;
      }
    }

    &-subtitle {
      font-size: 1.5rem;
      @extend .fw-600;
      margin-bottom: .5rem;
      color: var(--ra-color-dark);
    }

    &-desc {
      font-size: 1rem;
      color: var(--ra-color-grey);
      @extend .fw-500;
    }

    &-img {
      border-radius: 50%;
      margin: 0 auto 1rem;
    }

    &-data {
    @include respondMax(lg){
      margin-top: 2rem;
    }

      &--desc {
        font-size: 1rem;
        @extend .fw-500;
        color: var(--ra-color-grey);
      }

      &--switch{
        margin-left: 1rem;
      }

      &--title {
        font-size: 1.5rem;
        @extend .fw-600;
        margin: 1rem 0;
      }
    }

    &-btngroup{
      margin: 1rem 0 2rem;
      text-align: center;
    }

    &-lg{
      display: inline-block !important;
      white-space: normal !important; 
    }
  }

  &__data {
    margin-top: 1rem;
    display: flex;
    &-icon {
      margin-right: 0.5rem;
    }
    &-desc {
      @extend .fw-500;
      word-break: break-word;
      text-align: left;
    }
    &-icon,&-desc{
      color: var(--ra-color-dark) !important;
    }
  }
  &__profilebox{
    background-color: var(--ra-color-offwhite2);
    width: 100%;
    margin: 1rem 0 !important;
    border-radius: 0.5rem;
    padding-top: 1rem;
  }
  &__card-previewbtn{
      margin-bottom: 1rem;
      .business{
        &__card{
          padding: 1rem !important;
          &-heading{
            margin-left: 1rem;
          }
          &-title{
            @extend .fw-600;
            margin-bottom: 0.5rem;
            font-size: 1.25rem;
          }
          &-subtitle{
            font-size: 1rem;
          }
        }
      }

      button.v-btn{
          margin-top: 1.5rem;
          @include respondMax(md){
          margin-top: 1rem !important;
          }
      }
  }
}

.business-card {
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: var(--ra-color-offwhite2);
  border-radius: 0.5rem !important;
  padding: 1.5rem;
  text-align: center;


  & .media-display__profile-photo {
    margin: auto;
  }

  & .v-btn--plain {
    & .v-btn__content {
      opacity: 1 !important;
      font-size: 0.95rem;
    }
  }
}

.v-list-item__action.icon__align {
  align-self: start !important; 
}