.ra {
  &__carousel {
    // slick Slider Css
    .slick-arrow {
      background-color: rgb(64, 64, 64, 20%) !important;
      color: white !important;
      border-radius: 25% !important;
      margin: 0 !important;
      backdrop-filter: blur(48px) saturate(180%);
      box-shadow: 0 3px 9px rgba($color: #000000, $alpha: 0.08);
      width: 42px;
      height: 45px;
      top: 53% !important;
      z-index: 1;
      &::before {
        width: 100%;
        font-size: 3rem;
        height: 100%;
        line-height: inherit;
      }
      &.slick-disabled {
        &::before {
          opacity: 0.75 !important;
        }
      }
      &.slick-prev {
        left: -15px;

        &::before {
          content: "‹" !important;
        }
      }
      &.slick-next {
        right: -12px;

        &::before {
          content: "›" !important;
        }
      }
    }
    & .slick-list {
      padding: 1.5rem 0 1rem !important;
    }
    &-items {
      padding: 0 0.5rem;
      // max-width: 380px !important;
      margin-bottom: 1rem;
    }
    &-card { 
      -webkit-transition: transform 0.5s ease-in-out;
      -moz-transition: transform 0.5s ease-in-out;
      -o-transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
      transition: transform 0.5s ease-in-out;
      &:hover {
        @include respondMin(md){
          box-shadow: 0 3px 9px rgba($color: #000000, $alpha: 0.08);
          transform: translateY(-6px) scale(1.005);
        }        
      }
      &-schedule{
        color:  var(--ra-color-primary) !important;
      }
      .v-image {
        height: 185px;
        border-radius: 0.5rem;
      }
      &--default {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--ra-lobby-schedule_container_colour);
        .mdi-plus-circle-outline {
          color: $color-white;
        }
      }
      &--action {
        position: absolute !important;
        top: 0.5rem;
        right: 0.5rem;
        background-color: var(--ra-color-offwhite) !important;
      }
      &.theme--dark {
        & .ra__carousel-card--default {
          @extend .ra__blurbg-2;
        }
      }
    }
    &-body {
      padding: 1rem;
    }
    &-title {
      font-size: 1rem;
      @extend .fw-500;
      color: var(--ra-color-dark);
    }
    &-schedule {
      font-size: 0.875rem;
      color: var(--ra-color-grey1) !important;
      margin-bottom: 0.25rem !important;
      &--spacing{
        margin-top: 0.5rem;
      }
    }
    &-headline{
      margin: 1.5rem 0 0.25rem;
      &--space{
        margin: 2rem 0 0.5rem;
      }
      &--title {
        font-size: 1.2rem;
        @extend .fw-500;
        margin-bottom: 0.5rem;
        color: var(--ra-color-dark);
      }
    }
  }

  &__expo{
    &-headtitle{
      margin: 0.5rem 0 0.25rem;
    }
    &-desc{
      margin-bottom: 0 !important;
    }
    &-cardtitle{
      margin-bottom: 0 !important;
      &--theme {
        color: var(--ra-lobby-main_font_colour) !important;
      }
    }
    &-cardbody{
      padding:1rem 1rem 1.125rem !important;
    }
  }
}

// Arrow

button.slick-arrow.slick-prev.slick-disabled, button.slick-arrow.slick-next.slick-disabled {
  opacity: 0;
}

// Pulse
.pulse {
  &__icon {
    border-radius: 50%;
    animation: pulse-animation 2s infinite;

    &-red {
      animation: pulse-animation-red 2s infinite;
    }
    &-1 {
      animation-iteration-count: 1;
    }
    &-2 {
      animation-iteration-count: 2;
    }
    &-3 {
      animation-iteration-count: 3;
    }
  }
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
@keyframes pulse-animation-red {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 13, 13, 0.399);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(245, 172, 172, 0);
  }
}
