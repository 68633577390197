.ra{
    &__video{
      &-wrapper{
        position: relative;
      }  
        &-media{
          width: 100%;
          height: 100%;
          border-radius: 0.5rem;
        }
  
        &-panel{
          padding: 1rem;
          background-color: transparent;
          border-radius: 0 0 0.5rem 0.5rem;
          position: absolute;
          bottom: 0;
          width: 100%;
  
          @include respondMax(sm){
            padding: 0.5rem;
          }
  
          .v-btn.rounded-circle{
            margin: 0 0.225rem;
            @include respondMax(sm){
              margin: 0;
            }
            .v-icon{
              color: $color-white;
            }
          }
        }
  
        &-presenter{
          font-size: 1rem;
          @extend .fw-500;
          color: $color-white;
          padding: 0.5rem 1rem;
          border-radius: 2rem;
          backdrop-filter: blur(48px);
          -webkit-backdrop-filter: blur(48px);
          background-color: rgba(0, 0, 0, 0.5);
          display: inline-block;
          margin-bottom: 0;
          @include respondMax(sm){
            font-size: 0.75rem;
          }
          @include respondMin(sm) {
            margin-bottom: 0;
          }
        }
    }
} 