.homepage{ 
  margin: 0 -12px;
  &__carousel {
    .v-image {
      border-radius: 0.5rem;
    }
  }
  &__title{
    font-size: 3rem;
    line-height: 1.2;
    @extend .fw-700;
    color: var(--ra-color-dark);
    margin-bottom: 1rem;
    @include respondMax(md){
      font-size: 1.75rem ;
    }
  }
  &__subtitle{
    font-size: 1.5rem;
    @extend .fw-600;
    color: var(--ra-color-dark);
    margin-bottom: 1rem;
  }
  &__desc{
    font-size: 1rem;
    color: var(--ra-color-dark);
    margin-top: 1rem;
  }
  &__content{
    &-box{
      margin-bottom: 1.5rem;
    }
  }
  &__tab{
    margin-bottom: 2rem;
  }
  &__tabup{
    margin: 1.5rem 0;
  }
  &__banner {
    border-radius: 0.5rem;
  }

  //start banner CSS 
  &__intro{
    padding: 4rem 0 6rem;
    // background-image: url('../../../public/img/Banner-main.jpg') !important;
    background-image: url('https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Videos/Banner-main.jpg') !important;
    background-repeat: no-repeat !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-position: bottom !important;
    color: $color-white;
    &-banner { 
      border-radius: 0.5rem;
    }

    .content__box-title {
      color: $color-white !important;
    }

    @include respondMax(md) {
      padding: 2rem 0;
    }

    &-img {
      max-width: 180px !important;
      margin: 0 auto 1.5rem;
    }
    &-title {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      @extend .fw-500;
    }
    &-btn {
      margin-top: 1rem;

      @include respondMax(md) {
        margin-bottom: 2rem;
      }
    }
  }
  //end banner CSS

  //start stream CSS
  &__stream{
    padding: 0rem;
    background-color: $color-dark;
    &-upbox{
      width: 100%;
      height: auto;
    }
    &-content{
      padding:3rem 0;
      .homepage{
        &__title{
          color: $color-white;
          text-align: center;
          width: 100%;
          margin-bottom: 1.5rem;

          @include respondMax(md){
            text-align: left !important; 
          }
        }
        &__desc{
          color: $color-white !important;
        }
      } 
    }
    &-logobox{
      width: 100%;
      display: block;
      span{
        display: block;
        width: 100%;
        @include respondMax(md){
          margin-bottom: 1rem;
          
        }
      }
    }
  }
  //end stream CSS
  //start broadcast CSS
  &__broadcast{
    position: relative;
    &-imgbox{
      .v-image {
        border-radius: 0.5rem;
      }
      @include respondMax(md){
        display: block;
        margin-bottom: 1rem;
      }
    }
    &-box {
      margin-top: 5.5rem;
    }
    &-arrow {
      position: relative;
    }
    &-clickme {
      position: absolute !important;
      max-width: 150px !important;
      z-index: 5 !important;
      right: 0px;
      top: -100px; 
    }
    &-imgmodal {
      .v-image {
        border-radius: 0.5rem;
      }
    }
    &-icon {
      position: absolute;
      top: 2rem;
      right: 2rem;
    }
    &-content{
      padding-top:3rem;
      .homepage{
        &__title{
          color: $color-white;
          text-align: center;
          width: 100%;
          margin-bottom: 1.5rem;

          @include respondMax(md){
            text-align: left !important; 
          }
        }
        &__desc{
          color: $color-white !important;
        }
      }
    }
    &-bottom{
      display: block;
      width: 100%;
      margin-top: 1rem;
    }
    &-desc {
      color: var(--ra-color-dark) !important;
      @extend .fw-600;
      margin-top: 1rem;
    }
  }
  //end broadcast CSS
  //start easy learn CSS
  &__easy{
    background-color: var(--ra-color-offwhite2);
    padding: 3rem 1rem;
    &-video{
      display: block;
      @include respondMax(lg){
        margin-bottom: 3rem;
      }
      @include respondMax(md){
        margin-bottom: 1.5rem;
      }
      .v-image{
        border-radius: 1rem !important;
      }      
    }
    &-content{
      padding: 0 1rem;
      @include respondMax(md){
        padding: 0 !important;
      }
    }
    &-desc {
      margin-bottom: 1rem !important;
    }
  }
  //end easy learn CSS

  //start control CSS
  &__control{
    background-color: var(--ra-color-white);
    padding: 3rem 1rem;
    &-content{
      padding: 2rem 0;
      .homepage{
        &__title{
          color:var(--ra-color-dark);
          text-align: center;
          width: 100%;
          margin-bottom: 1.5rem;
          @include respondMax(md){
            text-align: left !important; 
          }
        }
      }      
    }
    &-imgbox {
      max-width: 900px;
      margin: auto;
      padding-bottom: 2rem;
    }
  }
  //end control CSS

  //start remote CSS
  &__remote{
    padding: 5rem 1rem;
    background: url('https://reattendance-video-public.s3.eu-west-2.amazonaws.com/Videos/homepage-remote.jpg') center center / cover;
    // background: url('../../../public/img/homepage-remote.jpg') center center / cover;

    &-content{      
      display: block;

      @include respondMax(lg){
        margin-bottom: 3rem;
      }
      @include respondMax(md){
        margin-bottom: 1.5rem;
      } 
          
    } 
  }
  //end remote CSS

  //start workfolio CSS
  &__workfolio{
    padding: 3rem 1rem;
    background: url('../../../public/img/homepage-workfolio.jpg') center center / cover;

    &-content{
      display: flex;
      align-items: center;
      flex-flow: column;
      justify-content: center;

      @include respondMax(md){
        margin-bottom: 1.5rem;
      }

      .homepage__smalltitle{
        color: #FFFFFF;
        font-size: 1rem;
        text-transform: uppercase;
      }

      .homepage__title{
        color:#FFFFFF;
        text-align: center;
        width: 100%;
        margin: 1.5rem 0;
        @include respondMax(md){
          text-align: left !important; 
        }
      }      
    }
    &-icon{
      cursor: pointer;
      .v-icon{
        color: #FFFFFF !important;
        font-size: 2.5rem !important;
      }
    }
  }

  //End workfolio CSS

  // start Event card css
  &__eventcard{
    background-color: var(--ra-color-white);
    padding: 3rem 1rem;
    &-boxcontent{
      padding: 1.5rem 0 1rem !important;
      .ra__carousel-card{
        margin-bottom: 1.5rem;
      }        
    }
    &-title{
      display: flex;
      align-items: center;
      justify-content: center;

      .homepage__title{
        text-align: center;
        width: 100%;
        margin: 1.5rem 0;
      }
    }
    &-video {
      margin: auto !important;
    }
    .ra__carousel-body {
      .ra__carousel-title {
        color: var(--ra-color-white) !important;
      }
      background-color: var(--ra-color-primary) !important;
    }
    &-desc {
      font-size: 1rem !important;
      color: var(--ra-color-dark) !important;
      @extend .fw-500;
      margin-top: 1rem;
    }
  }
  // start Event card css
}

