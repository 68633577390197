@import "../abstract/variables";

body {
  font-family: "Roboto", sans-serif;
  line-height: normal;
  color: var(--ra-color-dark);
  @extend .fw-400;
}

html {
  font-size: 0.875rem !important;

  @include respondMin(md) {
    font-size: 0.975rem !important; //15px
  }

  @include respondMin(lg) {
    font-size: 1rem !important; //16px
  }

  scroll-behavior: smooth;
}

:root {
  @each $color, $shade in $theme-colors--light {
      --ra-color-#{$color}: #{$shade};
  }
  @each $color, $shade in $lobby_colors {
    --ra-lobby-#{$color} : #{$shade};
  }
}

.theme--dark {
  @each $color, $shade in $theme-colors--dark {
      --ra-color-#{$color}: #{$shade};
  }
}
