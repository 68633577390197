.media-uploader {
  &__progress {
    margin-top: 20px;
  }
  &__drop-area {
    min-height: 450px;
    background-color: var(--ra-color-offwhite2);
    border: 1px dashed $color-offgrey;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 1.5rem 0 2rem;
    &--hover {
      opacity: 0.5;
    }
  }
  &__drop-text {
    margin-bottom: 0.5rem !important;
    font-size: 1.125rem;
    text-align: center;
  }
}
.ra {
  &__media {
    &-body {
      padding: 0 1.65rem;
    }
    &-divider {
      margin: 1rem 0 !important;
    }
    &-externalvideo {
      margin-top: 1rem;
      &--block {
        padding: 1rem;
      }
      &--title {
        padding: 1rem 0 !important;
      }
    }
    &-btn {
      margin: 1rem 0 2rem;
    }
    &-display {
      width: 100%;
      height: 100%;
    }
  }
  &__cropper {
    margin-top: 1.5rem;
    &-area {
      margin-bottom: 2rem;
    }
  }
  &__card {
    &-media {
      margin-bottom: 1.25rem;
      transition: all 0.3s ease-out;
      &:hover {
        box-shadow: 0px 16px 20px 2px rgb(154 160 185 / 3%), 0px 5px 40px 4px rgb(166 173 201 / 10%);
        transform: translateY(-6px) scale(1.005);
      }
      &--name {
        padding: 1rem 2rem 1rem 1rem;
        display: flex;
        align-items: center;
        word-break: break-word;
        font-size: 0.85rem;
      }
      &.media-item-card{
        transition: all 0.3s ease-out;
      }
      &.media-item-card--is-active { 
        background-color: var(--ra-color-offprimary2) !important;
        box-shadow: 0px 16px 20px 2px rgb(154 160 185 / 3%), 0px 5px 40px 4px rgb(166 173 201 / 10%);
        transform: translateY(-6px) scale(1.005);
        & .ra__card-media--name {
          @extend .fw-500; 
        }
      }
      &.media-item-card--is-not-selectable {
        & .ra__card-media--name{
        position: relative;
        @extend .fw-600;
        background-color: var(--ra-color-offwhite);
        &::before {
          position: absolute;
          content: "\F0AD";
          right: 10px;
          color: var(--ra-color-grey);
          display: inline-block;
          font: normal normal normal 24px/1 "Material Design Icons";
          font-size: 1.2rem;
          @extend .fw-700;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
        }
      }
    }
    }
    &-row {
      margin-top: 1.5rem !important;      
    }
    &-mediarow{
      height: 425px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 10px;
    }

    &-title {
      font-size: 1.5rem;
      @extend .fw-500;
      color: var(--ra-color-dark);
      margin-bottom: 0.5rem !important;
      @include respondMax(md) {
        margin-top: 1rem !important;
      }
    }
    &-desc {
      color: var(--ra-color-grey);
      margin: 1rem 0 2rem !important;
    }
    &-subtitle {
      font-size: 1.2rem;
      @extend .fw-500;
    }
    &-alt {
      margin-top: 1rem;
    }
  }
}
// Profile Cropper
.profile {
  &__btngroup {
  margin: 1.5rem 0px;
    .v-btn{
        margin: 0 1rem !important;
      }
  }

  &__btngroup1 {
    margin: 1.5rem 0.5rem 1rem;
  }

  &-image__container{
    background-color: var(--ra-color-offwhite1) !important;
  }
}
.user-cropper {
  &__crop-area {
    max-width: 1024px;
    // max-height:768px;
    margin: auto;
  }
}
.cropper{
  &-modal{
    background-color: transparent !important;
    opacity: 1;
  }
  &-bg{
    background-repeat: repeat;
  }
}