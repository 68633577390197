*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

html,
body,
div,
span,
section,
p,
a,
img,
ol,
ul,
li,
form,
label,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
select,
input,
option {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

button {
  border: none;
}

/* remember to define focus styles! */
a:focus {
  outline: 0;
  text-decoration: none;
}

button:focus {
  outline: none;
}


/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
}

caption,
th,
td {
  font-weight: normal;
}

a,
tr,
:hover {
  text-decoration: none;
  outline: none;
  transition: all 200ms ease-in-out 0s;
  -moz-transition: all 200ms ease-in-out 0s;
  -webkit-transition: all 200ms ease-in-out 0s;
}

a:hover {
  text-decoration: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

blockquote,
q {
  quotes: """";
}

table {
  border: 0 none;
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  border: 0 none;
  max-width: 100%;
  object-fit: cover;
}

.btn:focus, .v-expansion-panel::before {
  box-shadow: none !important;
}

.btn {
  line-height: normal;
}

/* End hide from IE Mac */
.none {
  display: none;
}

/* End Clearfix */
.clear {
  clear: both;
}

/* For modern browsers */
.cf:before,
.cf:after {
  content: "";
  display: table;
}

.cf:after {
  clear: both;
}

/* For IE 6/7 (trigger hasLayout) */


// Custom Scrollbar

/* width & Height */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--ra-color-offwhite2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--ra-color-grey1) !important;
}

h1,h2,h3,h4,h5,h6{
  margin: 0;
  @extend .fw-400;
}
.chat{
    /* Track */
  &::-webkit-scrollbar-track {
    background-color: var(--ra-color-offwhite) !important;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: var(--ra-color-grey1) !important;
  }

}

.eventdetails__schedule-presentersbox{
	&::-webkit-scrollbar{
		width:3px;
		height:6px;
	}
}
