
@import '../abstract/variables';

// Loop Color Shade 
@each $color,$shade in $theme-colors--dark {
    .text-#{$color} {
      color: var(--ra-color-#{$shade}) !important;
    }
    .bg-#{$color} {
      background-color: var(--ra-color-#{$shade}) !important;
    }
}