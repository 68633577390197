.blog {
  &__admin {
      &-btn {
          margin: 0.25rem;
      }
  }
}

.cms {
  &__box {
      border: 1px solid var(--ra-color-offwhite);
      border-radius: 0.5rem;
      background-color: var(--ra-color-white);
      margin-top: 1rem;
  }
  &__head {
      background-color: var(--ra-color-white);
  }
  &__input {
      margin-top: 1rem;
  }
  &__block {
      padding: 1rem;
      background-color: var(--ra-color-offwhite);
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      &-title {
          font-size: 1.25rem;
          color: var(--ra-color-dark);
          @extend .fw-500;
      }
      &-subtitle {
          font-size: 1.125rem;
          color: var(--ra-color-grey);
          @extend .fw-500;
          @include respondMax(md) {
              margin-bottom: 0.5rem;
          }
      }
      &-pills {
          padding: 0.25rem;
          background-color: var(--ra-color-white);
          border-radius: 5rem;
          margin-bottom: 0.5rem;
      }
      .quillWrapper {
          background-color: var(--ra-color-white);
          border-radius: 0.5rem;
          margin: 0;
      }
      &-media {
          padding: 1rem 0;
      }
      &-upload {
          padding: 1.5rem;
          border: 1px dashed var(--ra-color-dark);
          border-radius: 0.5rem;

          &--link {
              font-size: 1.25rem;
              padding-bottom: 0.5rem;
              display: block;
          }
          &--reset {
              margin-bottom: 0 !important;
          }
      }
  }
}
