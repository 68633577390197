.qr {
    &__scanner {
        &-block {
            margin-top: 1rem;
        }
        &-sheet {
            text-align: center;
            padding-top: 1rem !important;
        }
        &-title {
            font-size: 1rem;
            @extend .fw-600;
            color: $color-dark !important;
            margin-bottom: 1.5rem;
        }
        &-body {
            padding: 1.25rem;
            text-align: center;
            background-color: $color-white !important;
            border-radius: 0.5rem;
        }
        &-userimg {
            border-radius: 50%;
            width: 200px;
            height: 200px;
        }
        &-info {
            font-size: 1.5rem;
            text-align: center;
            color: $color-green2;
            @extend .fw-600;
            margin: 1rem 0;
            i {
                color: $color-green2 !important;
            }
        }
        &-fail {
            font-size: 1.5rem;
            color: $color-red;
            @extend .fw-600;
            text-align: center;
            margin: 1rem 0;
            i {
                color: $color-red !important;
            }
        }
        &-desc {
            font-size: 1.2rem;
            color: var(--ra-color-grey);
            margin: 1rem 0 1rem;
        }
    }
    &_btn {
        margin: 1rem 0 1rem;
        text-align: center;
    }
}

.side {
    &__wrapper {
        &-mobile {
            height: 100%;
            @include respondMin(md) {
                display: none !important;
            }
            & .v-bottom-navigation {
                &.theme--dark {
                    @extend .ra__blurbg-1;
                }
            }
        }
    }
    &__panel {
        &-mob {
            &--sheet {
                height: 100%;
                overflow: auto;
                padding: 1rem 0;
                text-align: center;
            }
            &--body {
                padding: 1rem 0 0;
                .v-list-item--dense, .v-list--dense .v-list-item {
                    min-height: 48px !important;
                    padding-left: 1rem !important;
                }
            }
            &--innerbody {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
        }
    }
}
