
@include respondMin(lg) {
  .add-media {
    min-height: 550px !important;
  }
}

.media{
  &-item {
    transition: all 0.3s ease-out;

    &:hover {
      box-shadow: 0px 16px 20px 2px rgb(154 160 185 / 5%),
        0px 5px 40px 4px rgb(166 173 201 / 20%);
      transform: translateY(-6px) scale(1.005);
    }
  }

  &-img{
    height: 140px;
  }
  
}
.my-media__sub-header{
  padding: 15px !important;
}
.v-input--selection-controls{
  margin-top: 10px;
}
.v-application--is-ltr .v-input--selection-controls__input{
  margin-right: 0px;
}
.clear-selection{
  margin: 15px 0px !important;
}
.media-item-card__checkbox{
  display: none;
}
.media-item-card{
  .v-image__image{
    border-radius: 0.5rem 0.5rem 0px 0px;
  }
}
.my-media__header--btngroup{
  margin-bottom: 1rem;
  @include respondMax(md){
    margin: 1rem 0;
  }
}
.my-media {
  &__header-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__meta-label {
    font-weight: bold;
    margin-right: 5px;
  }
}

.media-gallery__filters {
  margin-bottom: 1rem;
}