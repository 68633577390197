.chat {
  max-height: 420px;
  overflow: auto;
  &__box {
    margin-top: 0.5rem;
  }

  &__text {
    .v-label {
      top: calc(50% - 9px) !important;
    }

    .v-text-field__details {
      display: none !important;
    }
  }

  &__profile {
    &-1 {
      margin-right: 0.35rem;
    }

    &-2 {
      margin: 0 0.35rem 0 0.35rem;
    }
  }

  &__name {
    font-size: 0.9rem;
    @extend .fw-500;
    margin: 0.2rem !important;
    color: var(--ra-lobby-main_font_colour);

    &-time {
      font-size: 0.8rem;
      @extend .fw-400;
      color: $color-offwhite;
    }

    &-2 {
      text-align: right;
    }
  }

  &__send {
    padding-top: 0.5rem;
    margin-bottom: 0.5rem;

    .v-label,
    .v-input__control {
      font-size: 0.8rem;
    }

    .v-input__slot {
      background: var(--ra-color-offwhite1) !important;
      box-shadow: none !important;
      border: 1px solid $color-offwhite;
      margin-bottom: 0 !important;
    }
    &-wrapper {
      margin-left: 0.5rem;
    }
    &-btn {
      color: $color-primary !important;
    }
  }

  &__title {
    font-size: 1rem;
    @extend .fw-500;
    margin: 0;
  }

  &__head {
    padding-bottom: 0.25rem;
    &-divider {
      margin: 0 !important;
    }
  }

  &__message {
    padding: 0.3rem 0.5rem;
    max-width: 90%;
    position: relative;
    display: inline-block;

    &-1 {
      background-color: var(--ra-lobby-schedule_container_colour);
      border-radius: 0 0.5rem 0.5rem 0.5rem;
    }

    &-2 {
      background-color: var(--ra-lobby-schedule_container_colour);
      border-radius: 0.5rem 0 0.5rem 0.5rem;
      margin-left: auto;
    }

    &-text {
      font-size: 0.8rem;
      color: var(--ra-lobby-main_font_colour);
      margin-bottom: 0px !important;
    }

    &-icon {
      position: absolute;
      top: 0px; 

      &.chat-1 {
        right: -28px;
      }

      &.chat-2 {
        left: -24px;
      }

      &--delete {
        color: $color-offpink !important;
        margin-right: 0.25rem;
        cursor: pointer;
      }

      &--thumb {
        color: $color-primary !important;
        cursor: pointer;
      }
    }
  }

  &__mobile {
    padding: 0 1rem;
    text-align: left;

    &-sheet {
      background-color: var(--ra-color-white) !important;
    }
  }

  // Chat Popup
  &__popup {
    position: fixed;
    // bottom: 0px;
    // right: 24.125%;
    background-color: var(--ra-color-offwhite2); 
    border-radius: 0.5rem 0.5rem 0 0;
    border: 1px solid var(--ra-lobby-schedule_container_colour);
    box-shadow: 0 10px 40px rgba($color: var(--ra-color-dark), $alpha: 0.17);
    z-index: 90;
    cursor: move;
    
    // @include respondMin(xl) {
    //   right: 24.25%;
    // }
    // @include respondMax(lg) {
    //   right: 31.5%;
    // }
    @include respondMax(md) {
      bottom: 56px;
      right: 0;
      left: 0;
    }
    @include respondMin(md) {
      // max-width: 350px;
      width: 340px;
    } 
    
    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;
    }
    
    &-profileimg {
      width: 40px;
      height: 40px;
    }

    &-user {
      display: flex;
      align-items: center;
      margin-top: 0.4rem;
      
      &--profile {
        width: 32px;
        height: 32px;
        border-radius: 50%; 
        box-shadow: 0 10px 40px rgba($color: var(--ra-color-dark), $alpha: 0.7);
      }
      &--name {
        font-size: 0.85rem;
        line-height: normal;
        margin-left: 0.25rem;
      }
    }

    &-profile {
      margin: 0 1rem 0 0.5rem;
      max-width: 100px;
    }

    &-profilename {
      font-size: 0.9rem;
      @extend .fw-500; 
      line-height: normal;
    }

    &-btn {
      padding: 1rem !important;
      margin-left: 0.125rem;
    }

    &-body {
      padding: 0.5rem;
      max-height: 50vh;
      overflow: auto;
    }

    &-message {
      font-size: 0.9rem;
      line-height: 1.4;
      padding: 0.5rem 0.75rem;
      max-width: 80%; 
      display: inline-block;
      margin: 0.25rem 0;

      &--right {
        background-color: var(--ra-color-primary);
        border-radius: 1rem 1rem 0.25rem 1rem;
        margin-left: auto;
        color: var(--ra-color-white);
        text-align: left;
        @extend .fw-400;

        &--trash {
          position: absolute;
          opacity: 0.7;
        }
      }
      &--r {
        text-align: right;
      }

      &--left {
        color: var(--ra-color-dark);
        background-color: var(--ra-color-offprimary);
        border-radius: 1rem 1rem 1rem 0.25rem;

        &--rounded {
          border-radius: 1rem;
        }
      }

      &--time {
        display: none;
        &--action {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .v-btn.v-btn--icon {
            color: $color-offwhite2 !important;
            margin-left: 0.125rem;
          }
        }
      }

      &:hover &--time {
        display: block;
      }
    }

    &-action {
      padding: 0.5rem;
      box-shadow: 0 10px 40px rgba($color: var(--ra-color-dark), $alpha: 0.20);

      &--input {
        .v-text-field__details {
          display: none !important;
        }
        .v-input__control > .v-input__slot {
          padding: 0 1rem !important;
          background: var(--ra-lobby-main_font_colour);
        }
        .v-input__slot {
          &:hover {
            background: var(--ra-color-white) !important;
          }
        }
      }
      &--btn {
        margin-left: 0.5rem;
      }
      &--container {
        min-height: auto !important;
      }
      &--emoji {
        padding: 0.25rem !important;
        min-width: auto !important;
      }
    }
  }
  
  // List 
  &__messagegroup {
    position: relative;
    &-attendee {
      min-height: 40px !important;
      margin-bottom: 2px;
      .v-menu__content--fixed {
        max-height: 64vh;
      }
    }
    &-list {
      background-color: var(--ra-color-white);
      position: absolute;
      z-index: 9;
      top: 64px;
      left: 22px;
      border-radius: 1rem !important;
      box-shadow: 0px 0px 16px 4px rgb(0 0 0 / 20%) !important;
      max-width: 300px;
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom: 20px solid var(--ra-color-white);
        top: -14px;
        left: 37px;
        border-radius: 23px;
      }
      &--body {
        max-height: 60vh;
        overflow-y: auto;
        border-radius: 1rem 1rem 0 0;
        padding-top: 0.5rem;
      }
      &.networking__switch-mob {
        left: 75px !important;
      }      
    }
  }
}

#draggable {
  &-container {
    position: absolute;
    z-index: 9;

    @include respondMin(lg) {
      top: 380px;
      left: 1020px;
    }

    @include respondMin(md) {
      top: 220px;
      left: 680px;
    }
  }

  &-dragcomponent {
    z-index: 10;
  }
}