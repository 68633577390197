.v-dialog {
  box-shadow: none !important;
  border-radius: 0.5rem !important;
  overflow-x: hidden !important;

  @include respondMax(md) {
    border-radius: 0.5rem !important;
  }
}

#app.darkss {
  filter: invert(1) hue-rotate(180deg) !important;

  .v-image__image {
    filter: invert(100%) hue-rotate(180deg) !important;
  }

  transition: all 0s;
}

.content__box {
  padding: 1rem;

  &-title {
    font-size: 2rem;
    @extend .fw-600;
    margin-bottom: 1rem;
    color: var(--ra-color-dark);

    @include respondMax(md){
      font-size: 1.5rem;
    }

    &--reset{
      margin-bottom: 0 !important;
    }
  }

  &-spacing{
    margin-bottom: 4rem;
  }

  &-back--btn{
    margin-top: 0.5rem;
  }

  &-backspacing{
    width: 5rem;
  }
}

.menu__border {
  border-right: 1px solid var(--ra-color-bggrey);
}

.timezone {
  margin-left: auto;
  margin-bottom: 0.5rem;
  &__title {
    font-size: 1.2rem;
    @extend .fw-500;
    margin-bottom: 0.5rem;
    color: var(--ra-color-dark);
    text-align: right;
  }
  &__details {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.75rem;
    background-color: var(--ra-color-offprimary2);
    cursor: pointer;
    border-radius: 0.5rem;
    border: 1px solid var(--ra-color-bggrey);
  }

  &__country {
    font-size: 0.85rem;
    color: var(--ra-color-dark) !important;
    text-align: right;
    margin-right: 1rem;
    margin-left: 0.5rem;
    @extend .fw-500;
    &-chip {
      color: var(--ra-color-dark) !important;
    }
  }
  &__paragraph {
    font-size: 1rem;
    color: var(--ra-color-dark) !important;
    margin-bottom: 0 !important;
    padding-right: 1rem;
    text-align: right;
  }
  &__img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  &__description {
    text-align: center;
    font-size: 1rem;
    &--heading {
      font-size: 1.25rem;
      @extend .fw-600;
      padding: 1rem 0;
      color: var(--ra-color-dark);
    }
  }
  &__widget {
    display: inline-block;
    @include respondMin(sm) {
      position: absolute;
      right: 0;
    }
    &-public {
      right: 28px;
    }
    .timezone {
      &__country {
        white-space: nowrap !important;
      }
      &__details {
        background-color: var(--ra-lobby-schedule_container_colour) !important;
        padding: 0.25rem 0.25rem 0.25rem 0.5rem;
        border: none;
      }
      &__img {
        width: 40px;
        height: 40px;
      }
    }
  }
}



.dashboard{
  &__tabcard {
    margin-bottom: 1.5rem;
    max-width: 100% !important;
    .v-image{
      @include respondMax(md){
        height: 225px;
      }
      @include respondMax(sm){
        height: 184px;
      }
    }
    
  }
  &__tabbar{
    .v-toolbar{
        margin: 1.5rem 0;
    }
    .v-slide-group{
      &__next{
        flex: 0 1 30px !important;
        min-width: 30px !important;
      }
      &__prev{
        flex: 0 1 30px !important;
        min-width: 30px !important;
      }
    }
  }
  &__tabs{
    .v-tab{
      padding: 0 30px !important;
      font-size: 1rem;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      @include respondMax(sm){
        padding: 0 15px !important;
      }
    }
  }
}

