@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$font-size: () !default;
$font-size: map-merge(("14": 0.875rem,
    "15": 0.9rem,
    "16": 1rem,
    "17": 1.0625rem,
    "18": 1.125rem,
    "20": 1.25rem,
    "24": 1.5rem,
    "30": 1.875rem,
    "32": 2rem,
    "36": 2.25rem,
    "60": 3.75rem,
  ),
  $font-size);

@each $fz-size,
$value in $font-size {
  @include font-size(".fz-#{$fz-size}", $value);
}

$font-weight: () !default;
$font-weight: map-merge((
    "300":300, 
    "400":400, 
    "500":500,
    "600":600,
    "700":700,
  ),
  $font-weight);

@each $fw-weight,
$value in $font-weight {
  @include font-weight(".fw-#{$fw-weight}", $value);
}


// Font Size

h5 {
  font-size: 1.125rem; //18px
}

.h6-sm{
  font-size: 1rem; //14px
}