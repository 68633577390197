.onboarding{
    .v-step {
        background: $color-white !important;
        max-width: 420px !important;
        color: $color-dark !important;
        border-radius: 0.5rem !important;
        margin: 10px 0 !important;
        & p {
            font-size: 0.9rem;
        }
        @include respondMax(md) {
            max-width: 300px !important;
        }
        &__arrow--dark:before{
            background: $color-white !important;
        }
        &__header{
            background-color: $color-white !important;
            @extend .fw-500;
            border-bottom: 1px solid var(--ra-color-bggrey);
            border-radius: 0.5rem 0.5rem 0 0 !important;
        }
        &__buttons {
            margin-top: 1rem;
        }
        &__button {
            height: auto !important;
            line-height: normal !important;
            padding: 0.75rem 1rem !important;
            border-radius: 0.25rem !important;
            &-next, &-stop {    
                color: $color-white !important;
                background-color: $color-primary !important;
            }
            &-previous {    
                color: $color-primary !important;
                border-color: $color-primary !important;
            }
            &-skip {    
                color: $color-dark !important;
                border-color: $color-bggrey !important;
            }
        }
    }
    &__backdrop{
        &-block {
            position: relative;
            z-index: 501;
        }
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 120;
        background-color: rgba(0,0,0,.3);
    }
    // Custom CSS positioning
    #v-step {
        &-97768b8a {
            position: fixed !important;
            bottom: 8px !important;
        }
    }
}
.v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0,0,0,.4) !important;
}