.btn {
  &-custom {
    font-size: 1.375rem !important;
  }

  &-modal {
    font-size: 1.125rem !important;
  }

  &-primary {
    color: $color-white !important;
    background-color: var(--ra-color-primary) !important;
  }

  &-danger {
    color: $color-white !important;
    background-color: $color-red !important;
  }

  &-success {
    color: $color-white !important;
    background-color: $color-green2 !important;
  }

  &-outline-primary {
    border-color: var(--ra-color-primary) !important;
    color: var(--ra-color-primary) !important;

    &:hover {
      background-color: var(--ra-color-offwhite) !important;
    }
  }

  &-outline-danger {
    border-color: $color-red !important;
    color: $color-red !important;

    &:hover {
      background-color: var(--ra-color-offwhite) !important;
    }
  }

  &-filled {
    color: var(--ra-color-primary) !important;
    border: 1px solid var(--ra-color-primary) !important;
  }
}

.v-btn.v-btn--disabled {
  border-color: var(--ra-color-offgrey) !important;
} 

.mdi-settings,
.mdi-settings-outline,
.mdi-plus-circle-outline,
.mdi-close {
  transition: transform 0.3s cubic-bezier(0.98, 0.55, 0.79, 1.36) !important;

  &:hover {
    transform: rotate(90deg) scale(1.3);
  }
}

.event-btn {
  max-width: max-content !important;
  height: auto !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

button {
  text-transform: capitalize !important;
}

.status {
  &-save {
    background-color: #7de5a0 !important;
  }
}

.steps-btn {
  font-size: 1.125rem !important;
}
