.business__card {
  height: auto !important;
  border-radius: 0.25rem !important;
  padding: 0.5rem !important;
  width: 100%;

  &-spacing {
    margin-top: 0.5rem;
  }

  &-img {
    border-radius: 50%;
  }

  &-heading {
    margin-left: 0.75rem;
  }

  &-title {
    font-size: 1rem;
    margin-bottom: 0.25rem;
    white-space: normal !important;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--ra-color-dark);
    @extend .fw-500;
    &--1 {
      margin-bottom: 0.5rem !important;
    }

    &--sm {
      font-size: 1.25rem;
      @extend .fw-600;
      margin-bottom: 1.5rem;
    }
  }

  &-subtitle {
    font-size: 0.975rem;
    color: var(--ra-color-dark);
    margin: 0 !important;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-divider {
    margin-top: 0 !important;
  }

  &-spacing {
    margin-bottom: 1rem;
    span.v-chip  {
      min-height: 70px;
      border-radius: 0.25rem;
      width: 100%;
      background-color: var(--ra-lobby-schedule_container_colour) !important;
      color: var(--ra-lobby-main_font_colour) !important;
    }
    &--lobby {
      .business__card {
        &-title {
          color: var(--ra-lobby-main_font_colour) !important;
        }
        &-sort {
          &--list {
            color: var(--ra-lobby-main_font_colour) !important;
          }
          .active {
            color: var(--ra-lobby-main_font_colour) !important;
          }
        }
      } 
    }
  }

  &-content {
    background-color: var(--ra-color-white);
    text-align: center;
    padding: 1.5rem;
    border-radius: 0.5rem;

    &--profile {
      margin: auto;
      border-radius: 50%;
      margin-bottom: 1rem;
    }
  }

  &-preview {
    background-color: var(--ra-color-offwhite2) !important;
    padding: 1.5rem 1rem;
    text-align: center;
    border-radius: 0.5rem !important;

    &-title {
      font-size: 1.125rem;
      @extend .fw-500;
    }

    &-subtitle {
      font-size: 1.125rem;
      @extend .fw-500;
      color: var(--ra-color-dark);
    }
  }

  &-details {
    @extend .fw-600;
    text-transform: lowercase !important;
    color: $color-grey;

    &--icon {
      margin-right: 0.5rem;
    }
  }

  &-desc {
    white-space: normal;
  }

  &-menu {
    max-width: 1045px !important;

    .v-avatar {
      width: 60px !important;
      height: 60px !important;
    }
  }

  // custom
  &-menu1 {
    .v-avatar {
      width: 60px !important;
      height: 60px !important;
    }
  }

  &-desc1 {
    font-size: 1rem !important;
    max-width: 400px;
    white-space: normal !important;
    line-height: 1.5 !important;
  }

  //

  &-sort {
    &--divider {
      border-color: $color-offgrey !important;
    }
    &--list {
      padding: 0 0.4rem !important;
      min-width: auto !important;
    }
    & .active {
      font-size: 1.125rem;
      @extend .fw-600;
      opacity: 1 !important;
      border: 1px solid;
    }
  }
}

.main-title {
  font-size: 1.875rem;
  @extend .fw-600;
}

.collected__businesscard-bio {
  margin-bottom: 0.5rem;
}
