.eventsearch{
  &__row{
    margin: -12px!important;
  }
  &__wrapper{
    background-color: var(--ra-color-offwhite2);
    padding: 3rem;
    width: 100%;
    @include respondMax(md){
      padding: 1.25rem;
    }
  }
  &__maintitle{
    display: flex;
    justify-content: center;
    flex-flow: column;
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &__desc{
    font-size: 1rem;
    color: var(--ra-colo-dark);
    margin-bottom: 0.5rem !important;

    &-link{
      color: $color-primary;
    }
  }
  &__card{
    &-box{
      background-color: var(--ra-color-white);
      border-radius: 1rem;
      padding: 1.5rem 2rem;
      margin-bottom: 1.5rem;
      @include respondMax(md){
        padding: 1.25rem;
      }
    }
    &-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
    }
    &-viewall{
      cursor: pointer;
      color: var(--ra-color-primary);
      .v-icon{
        color: var(--ra-color-primary) !important;
      }
    }
    &-title{
      font-size: 1.5rem;
      @extend .fw-600;      
      color: var(--ra-color-dark); 
      margin-bottom: 1rem;
    }
  }
  &__modal{
    &-body{
      padding: 1rem 0;
    }
    &-startend{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0;
      p{
        font-size: 1.125rem;
        color: var(--ra-color-dark);
      }
    }
    &-imgbox{
      margin-bottom: 1rem;
      .v-image{
        border-radius: 0.5rem;
      }
    }
    &-desc{
      p{
        font-size: 1rem;
        color: var(--ra-color-dark);
        margin-bottom: 1rem !important;
      }
    }
    &-action{ 
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
