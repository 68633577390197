.expocompany{
  &__pointer{
    cursor: pointer;
  }
  &__box{
    margin: 2rem 1rem;
    &-btn {
      margin-bottom: 1rem;
    }
    .v-sheet {
      background-color: var(--ra-lobby-schedule_container_colour);
    }
  }
  &__tabs{
    .v-tab{
      padding: 0 30px 0 15px !important;
      @extend .fw-600;
      font-size: 1rem;
      letter-spacing: 0.5px;
      text-transform: capitalize;
      color: var(--ra-lobby-main_font_colour) !important;
    }
    .v-icon {
      color: var(--ra-lobby-main_font_colour) !important;
    }
  }
  &__info{
    margin: 3.5rem 0;
    @include respondMax(md){
      margin: 1.5rem 0;
    }
    .info__header{
      &-title{
        font-size: 2.5rem;
        @extend .fw-700;
        color: var(--ra-color-dark);
        
      }
      &-description{
        font-size: 1rem;
        color: var(--ra-color-offwhite);
        margin: 1.5rem 0;
        text-align: center;

        @include respondMax(md){
          text-align: left;
        }
      }
    }
  }
  &__card{
    margin-top: 3rem;
    background-color: var(--ra-lobby-schedule_container_colour);
    border-radius: 0.5rem;
    padding: 3rem;
    @include respondMax(md){
      padding: 1.5rem;
    }
    &-header{
      margin-bottom:1rem;
      text-align: center;
      font-size: 2rem;
      @extend .fw-600;
      color: var(--ra-color-dark);
      i.v-icon{
        font-size: 2rem;
        padding-left: 1rem;
        margin-bottom: 8px;
      }
    }

    &-img{
      .v-image {
        border-radius: 0.5rem;
      }
      @include respondMax(md){
        margin-bottom: 1.5rem;
      }
    }
    &-boxtitle{
      margin-bottom: 1rem;
      font-size: 1.75rem;
      @extend .fw-600;
      color: var(--ra-color-dark);
      span{
        font-size: 1.25rem;
        color: var(--ra-color-grey);
      }
    }
    &-boxdetail{
      font-size: 1.125rem;
      color: var(--ra-color-dark);
      margin-bottom: 1rem;
      white-space: normal;
      display: flex;
      word-break: break-word;
      span{
        padding-right: 8px;
        i.v-icon{
          color: var(--ra-color-grey);
          font-size: 1.75rem;
        }
      }
    }
  }
  &__offer{
    margin-top: 3rem;

    &-img{
      margin:0 auto 3rem;
      max-width: 600px;
      height: auto;
      .v-image {
        border-radius: 0.5rem;
      }
      @include respondMax(sm){
        width: 100%;
      }
    }
    &-title{
      font-size: 2.25rem;
      @extend .fw-700;
      color: var(--ra-color-dark);
    }
    &-description{
      font-size: 1.25rem;
      line-height: 2rem;
      color: var(--ra-color-grey);
      margin: 1.5rem auto !important;
    }
  }
  &__editbtn{
    @include respondMax(md){
      margin: 2rem 0 1rem;
    }
  }
  &__talk{
    margin: 3.5rem 0;
    @include respondMax(md){
      margin: 1.5rem 0;
    }

    &-title {
      margin-top: 1.5rem;
      padding: 0 12px;
    }

    &-content{
      &-desc{
        font-size: 1.25rem;
        line-height: 2rem;
        margin: 1.5rem auto !important;
      }
    }

    &-modalimg {
      max-width: 100px !important;
      margin: 0 auto 1rem;
    }
  }
  &__notesbox{
    padding: 2rem;
    background-color: var(--ra-lobby-schedule_container_colour);
    color: var(--ra-lobby-main_font_colour);
    border-radius: 0.5rem;
    margin: 20px 12px;
    @include respondMax(md){
      padding: 1.5rem;
    }
    &-header{
      margin-bottom: 1.5rem;
      h3{
        font-size: 1.25rem;
        @extend .fw-600;
      }
    }
    &-list{
      margin-bottom: 1.5rem;
    }
    &-listitem{
      position: relative;
      font-size: 1.125rem;
      padding-left: 1.5rem;
      &::before{
        content: '';
        position: absolute;
        width: 7px;
        height: 7px;
        top: 10px;
        left:0;
        border-radius: 50%;
        background-color: $color-primary;
      }
    }
    &-notes{
      p{
        font-size: 1.125rem;
        font-style: italic;
      }
    }

  }
  &__talk-btn{
    @include respondMax(md){
      margin-bottom:1.5rem;
    }
  }
  &__download{
    margin: 3.5rem 0;
    @include respondMax(md){
      margin: 1.5rem 0;
    }
    &-header{
      margin-bottom: 1.5rem;
    }
    &-itembox{
      border-radius: 0.5rem;
      background-color: var(--ra-lobby-schedule_container_colour);
      margin-bottom: 1.5rem;
    }
    &-img{
      width: 100%;
      height: 100%;

      .v-image{
        border-top-left-radius:12px;
        border-bottom-left-radius:12px;
        @include respondMax(md){
          border-top-left-radius:12px;
          border-top-right-radius:12px;
          border-bottom-left-radius:0px;
        }
      }
    }
    &-contentbox{
      @include respondMax(md){
        margin:1.5rem 0;
      }
    }
    &-content{
      flex-basis: 85%;
      @include respondMax(md){
        padding: 0 1rem;
      }
      .download__content-title{
        display: block;
        font-size: 1.25rem;
        color: var(--ra-color-dark);
        @extend .fw-600;
        margin-bottom: 0.5rem;
        @include respondMax(sm){
          font-size: 1.125rem;
        }
      }
      .download__content-dec{
        margin-bottom: 0;
        font-size: 1.125rem;
        color: var(--ra-color-grey);
        @extend .fw-400;
        @include respondMax(sm){
          font-size: 1rem;
        }
      }
    }
    &-icon{
      @include respondMax(md){
        padding: 0 1rem;
        margin-bottom:0;
      }
      .v-icon{
        font-size: 2.5rem !important;
      }
    }
  }
  &__companylogo{
    margin-bottom: 1rem;
    .v-image {
      border-radius: 0.5rem;
    }
  }
  &__heading{
    font-size: 1.25rem;
    @extend .fw-600;
    text-align: center !important;
  }
  &__media{
    width: 100%;
    .v-image {
      border-radius: 0.5rem;
    }
  }
}

.position-absolute{
  position: absolute;
}

.expo{
  &__carousel{
    .ra__carousel-card{
      .v-image{
        @include respondMax(xl){
          height: 165px !important;
        }
        @include respondMax(lg){
          height: 185px !important;
        }
      }
    }
    .v-card {
      background-color: var(--ra-lobby-schedule_container_colour) !important;
    }
  }
}