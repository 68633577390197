.ra{
  &__contain{
    &-title{
      @extend .fw-600;
      margin-bottom: 1.5rem;
    }
    &-link{
      color: var(--ra-color-primary);
    text-align: center;
    margin-top: 1.2rem;
    }
  }
}