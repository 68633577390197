.invite{
  &__box{
    background-color: var(--ra-color-white);
    border-radius: 0.5rem;
    padding: 1rem 5rem;

    @include respondMax(md){
      padding: 1.5rem;
    }
  }
  &__header{
    text-align: center;
  }
  &__box-subtitle{
    font-size: 1.5rem;
    @extend .fw-500;
    margin-bottom: 1.5rem;
    @include respondMax(md){
      font-size: 1.25rem;
    }
  }
  &__box-title{
    font-size: 2.5rem;
    @extend .fw-600;
    margin-bottom: 1.5rem;
    @include respondMax(md){
      font-size: 2rem;
    }
  }
  &__box-description{
    font-size: 1.2rem;
    line-height: 2rem;
    color: #5b5b5b;
    margin-bottom: 2rem !important;
  }
  &__eventbox{
    margin-top: 2rem;

  }
  &__imagebox{
    @include respondMax(lg){
      margin-bottom: 1.5rem;
    }
    .v-image{
      border-radius: 0.5rem !important;
      }
  }
  &__middle-img{
    @include respondMax(lg){
      transform: rotate(90deg);
      max-width: 30px;
      height: 40px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-image{
      max-width: 40px;
      height: auto;
      @include respondMax(lg){
        width: 30px;
      }
    }
  }
  &__form{
    background-color: var(--ra-color-offwhite);
    padding: 2rem;
    border-radius: 0.5rem;
    @include respondMax(md){
      padding: 1.5rem;
    }
    @include respondMax(lg){
      margin-top: 1.5rem;
    }
    .v-btn{
      width: 100%;
    }
  }
  &__small-desc{
    font-size: 0.95rem !important;
  }
}

