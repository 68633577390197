.footer{
    &__wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      padding: 2rem;
      background-color: $color-primary;
      @include respondMax(sm){
        padding: 1rem;
    }
    }
    &__menu{
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      flex-wrap: wrap;
      justify-content: center;
      &-link{
        color: $color-white !important;
        text-decoration: none;
        text-transform: uppercase;
        font-size: 0.875rem;
        @extend .fw-500;
        padding: 0 1rem ;
        white-space: nowrap;
        @include respondMax(sm){
            padding: 0 1rem 1rem 0;
        }
      }
    }
    &__address{
      text-align: center;
      color: $color-white;
      &-tag{
        margin-bottom: 1rem;
        font-size: 0.75rem;
      }
      &-copyright{
        font-size: 0.875rem;
        margin-bottom: 0 !important;
      }
    }
  }