.event {
  &__title {
    @extend .fw-600;

    &-sm {
      font-size: 0.875rem;
      text-align: left;
    }
  }

  &__btn {
    text-align: left;

    &-main {
      padding: 0.5rem 0; //8px 0
      height: auto;
      border-radius: 0.5rem; //8px
    }
  }

  &__pills {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    border-radius: 2rem;
    display: inline-block;
    color: var(--ra-color-white);
    letter-spacing: normal !important;
    margin-right: 0.5rem !important;

    &-html {
      background-color: $color-html;
    }
    &-rs {
      background-color: $color-rs;
    }
    &-rtmp {
      background-color: $color-rtmp;
    }
    &-es {
      background-color: $color-es;
    }
    &-iframe {
      background-color: $color-iframe;
    }
    &-prv {
      background-color: $color-prv;
    }
  }

  &__status {
    &-info1 {
      margin-bottom: 0.5rem !important;
    }
  }

  &__icon{
    &-spacing{
      margin-right: 0.5rem;
    }
  }
}

.expo-block {
  animation: expo 5s ease-in-out;
}

@keyframes expo {
  from {
    /* height: 100%; */
    transform: scale(1);
  }

  to {
    /* height: 0; */
    transform: scale(0);
  }
}

.btn-border1 {
  position: relative;
}

.btn {
  &-border1::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 50%;
    left: -48px;
    width: 48px;
    background: $color-offgrey;
    height: 1px;

    animation: horLine 2s ease;
  }

  &-name {
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: left;
    margin-bottom: 0 !important;
    font-size: 0.95rem;
    @extend .fw-400;
    letter-spacing: normal !important;

    @include respondMax(xl) {
      max-width: 275px !important;
      padding-right: 0.25rem;
    }
  }
}

.event-order1 {
  @include respondMax(lg) {
    order: 2 !important;
  }
}

.event-order2 {
  @include respondMax(lg) {
    order: 1 !important;
  }
}

.events-innerblock > * {
  animation: scaleup 0.4s cubic-bezier(0, 0.37, 0.34, 1.15);
}

@keyframes scaleup {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border {
  opacity: 0 !important;
}

.events-innerblock {
  overflow: visible;
  transition: height 0.5s ease-in-out;
}

.event {
  &__tooltip {
    max-width: 500px !important;
    min-width: 400px !important;
    border: 1px solid var(--ra-color-offgrey);
    position: absolute;
    left: -520px;
    top: 0;
    background-color: var(--ra-color-white);
    z-index: 9;
    border-radius: 0.5rem;

    @include respondMax(md){
      min-width: 100% !important;
      left: 0px;
      top: 55px;
    }

    &::after {
      content: "";
      position: absolute;
      right: -22px;
      top: 15px;
      width: 0;
      height: 0;
      border-left: 16px solid transparent;
      border-right: 16px solid transparent;
      border-top: 16px solid var(--ra-color-offwhite);
      transform: rotate(270deg);
      border-radius: 3rem;

      @include respondMax(md){
        left: 0;
        top: -15px;
        right: auto;
        transform: rotate(180deg);
      }
    }
    &-title {
      font-size: 1.2rem;
      @extend .fw-600;
      color: var(--ra-color-dark) !important;
      background-color: var(--ra-color-offwhite);
      padding: 1rem;
      border-radius: 0.5rem 0.5rem 0 0;
    }

    &-body {
      padding: 1rem;
      border-radius: 0.5rem;
    }

    &-desc {
      color: var(--ra-color-dark) !important;
      margin-bottom: 0 !important;
    }
  }

  &__status{
    &-info, &-info1{
      color: var(--ra-color-dark) !important;
    }
  }

  &-tooltip-publish {
    z-index: 99;
    background-color: var(--ra-color-white);
  }

  &-publish {
    &.v-menu {
      z-index: 99 !important;
    }
  }

  &__sales-desc {
    margin-bottom: 0.5rem !important;
  }
}

.v-item-group.v-bottom-navigation--fixed {
  z-index: 55 !important;
}

.ticket-block {
  max-width: 400px !important;
}

.event-table {
  td {
    vertical-align: inherit !important;
  }
}

@include respondMin(md) {
  .border-md-right {
    border-right: 1px solid var(--ra-color-offwhite) !important;
  }
}

// Event New Blocks

.eventbuilder {
  position: relative;
  margin: 1rem 0;
  z-index: 5;
  @include respondMax(md){
    overflow-x: auto;
    padding-bottom: 320px;
  }

  &__innersub {
    &-block {
      display: flex;
      align-items: center;
      padding-top: 20px;
      padding-left: 96px;
      position: relative;
      margin-left: 96px;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 48px;
        width: 1px;
        height: 100%;
        background-color: $color-offgrey;
      }
      &:last-child {
        &::after {
          height: calc(50% + 10px) !important;
        }
      }
    }
    & .outer1{
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 48px;
        width: 1px;
        height: 100%;
        background-color: $color-offgrey;
      }
    }
  }

  &__drag {
    &-icon {
      margin: 0 5px;
    }
  }

  &__menu-list{
    background-color: var(--ra-color-bggrey) !important;
  }

  &__cta {
    margin: 1rem 0;
    position: relative;
  }

  &__main {
    &-block {
      height: auto !important;
      padding: 0.5rem 1rem !important;
      margin-right: 0.5rem;
    }
    &-title {
      letter-spacing: 1px !important;
      color: $color-dark !important;
    }
    &-subtitle {
      font-size: 0.875rem;
      margin: 1.25rem 0rem 0.25rem;
      width: 100%;
      text-align: left;
      color: $color-dark !important;
      @extend .fw-600;
      letter-spacing: normal !important;
    }
  }

  &__icontray {
    margin-left: 1rem;

    &-primary {
      color: var(--ra-color-primary) !important;
    }
  }

  &__sponsor {
    font-size: 1rem;
    @extend .fw-400;
    margin-top: 0.5rem;
    color: $color-grey !important;

    &-icon {
      color: var(--ra-color-primary);
    }

    &-name{
      margin-left: 0.5rem;
    }

    &-img {
      .slider__card-img,
      .v-responsive__content {
        width: 55px !important;
        height: 35px !important;
        margin-right: 0.5rem;
        border-radius: 0.25rem;
      }
      .v-image__image{
        border-radius: 0.25rem;
        width: 55px;
        height: 35px;
      }
    }
  }

  &__ticket {
    padding: 1rem;
    margin: 0.3rem 0.3rem 0.3rem 0;
    color: var(--ra-color-white) !important;
    border: none !important;

    &.bg-success {
      background-color: $color-offgreen;
    }

    &.bg-danger {
      background-color: $color-red;
    }
  }

  &__sub {
    position: relative;

    &-block {
      &--inner {
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 96px;
        padding-top: 20px; //20px
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 48px;
          width: 1px;
          height: 100%;
          background: $color-offgrey;
          animation: verLine 3s ease;
        }
      }
    }

    &:last-child {
      & .eventbuilder {
        &__sub {
          &-block {
            &:last-child{
              & .eventbuilder__sub-block--inner {
                &::after {
                  height: calc(50% + 10px) !important;
                }
              }
              & .outer1{
                &::after{
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .v-btn > .v-btn__content .v-icon {
    width: 30px !important;
    height: 30px !important;
    color: $color-dark !important;

    &.mdi-plus-circle-outline {
      color: var(--ra-color-dark) !important;
    }
    &.help__info-title--icon {
      color: var(--ra-color-primary) !important;
    }
  }

  &__subtitle {
    font-size: 14px;
    @extend .fw-400 ;
    margin-bottom: 0 !important;
    color: $color-dark !important;
  }

  &__rag-warning {
    max-width: 450px !important;
    .v-list-item__title {
      white-space: normal !important;
      padding-bottom: 0.5rem;
      line-height: 1.5;
    }
  }
}

// Drag and drop functionality CSS
.btn-modal {
  border: 2px dashed transparent !important;
  transition: all 0.3s ease-in-out;
}

.drag-start {
  border-color: var(--ra-color-primary) !important;
  background-color: var(--ra-color-offwhite) !important;
}

.drag-over {
  background-color: $color-offprimary !important;
}

.draggable__ticket-btn {
  background-color: $color-offlightorange !important;
  z-index: 99;
}
// Animation

@keyframes verLine {
  to {
    height: 100%;
  }

  from {
    height: 0%;
  }
}

@keyframes horLine {
  to {
    width: 48px;
  }

  from {
    width: 0px;
  }
}

// color for rag

.eventbg {
  &-red {
    background-color: var(--ra-color-bgred) !important;
  }
  &-yellow {
    background-color: var(--ra-color-bgyellow) !important;
  }
  &-grey {
    background-color: var(--ra-color-bggrey) !important;
  }
  &-green {
    background-color: var(--ra-color-bggreen) !important;
  }
}

.info__query {
  &-popup {
    background-color: var(--ra-color-white);
    position: absolute;
    z-index: 9;
    top: 40px;
    right: 0;
    border-radius: 1rem !important;
    padding: 0.5rem 1rem;
    box-shadow: 0px 0px 16px 4px rgb(0 0 0 / 20%) !important;
    max-width: max-content;
    min-width: 320px;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid var(--ra-color-white);
      top: -15px;
      right: 55px;
      border-radius: 23px;
    }
    @include respondMax(lg){
      left: 0;
      right: auto;
      min-width: 260px;

      &::before{
        display: none;
      }
    }

    .v-btn > .v-btn__content .v-icon {
      color: inherit !important;
    }

    .theme--dark.v-list{
      background-color: transparent;
    }
  }
  &-title {
    @extend .fw-600;
    text-align: left;
    font-size: 1.2rem;
    letter-spacing: normal;
    margin-bottom: 0;
  }
  &-sublist {
    color: var(--ra-color-dark);
    margin-bottom: 0.5rem;
    @extend .fw-400;
  }
  &-desc {
    margin-bottom: 0.5rem;
    white-space: normal;
    @extend .fw-600;
  }
  &-block {
    text-align: left;
    letter-spacing: normal !important;
    span {
      font-size: 0.9rem;
      text-align: left;
    }
  }
}

.v-application {
  & .event {
    &__status {
      &-switch {
        margin-left: 1rem;
        color: $color-red !important;
        caret-color: $color-red !important;
        &.primary--text {
          color: $color-offgreen !important;
          caret-color: $color-offgreen !important;
        }
        .v-input--switch__track,
        .v-input--selection-controls__ripple,
        .v-input--switch__thumb {
          color: inherit !important;
        }
      }
    }
    &__publish-cta {
      button {
        span {
          color: var(--ra-color-dark) !important;
        }
      }
    }
  }
}

.event-publish {
  box-shadow: none !important;
}

.quillWrapper{
  width: 100%;
  height: 100%;
  display: block;
  margin-bottom: 2rem;
}

.collapse-hide{
  margin-left: 0.5rem;
}

.help__info {
  &-popup {
    background-color: var(--ra-color-white);
    position: absolute;
    z-index: 9;
    top: 40px;
    right: 0;
    border-radius: 1rem !important;
    padding: 0.5rem 1rem;
    box-shadow: 0px 0px 16px 4px rgb(0 0 0 / 20%) !important;
    max-width: max-content;
    min-width: 400px;
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid var(--ra-color-white);
      top: -15px;
      right: 25px;
      border-radius: 23px;
    }
    @include respondMax(lg){
      left: 0;
      right: auto;
      min-width: 300px;

      &::before{
        display: none;
      }
    }

    .v-btn > .v-btn__content .v-icon {
      color: inherit !important;
    }

    .theme--dark.v-list{
      background-color: transparent;
    }
  }
  &-title {
    text-align: left;
    font-size: 1.2rem;
    @extend .fw-600;
    letter-spacing: normal;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    &--icon{
      color: var(--ra-color-primary) !important;
      margin-right: 0.25rem;
    }
  }
  &-sublist {
    color: var(--ra-color-dark);
    margin-bottom: 0.5rem;
    @extend .fw-400;
  }
  &-desc {
    margin-bottom: 0.5rem;
    white-space: normal;
    @extend .fw-400;
    text-transform: initial !important;
  }
  &-block {
    text-align: left;
    letter-spacing: normal !important;
    margin-top: 0.5rem;
    span {
      font-size: 0.9rem;
      text-align: left;
      line-height: 1.2rem;
    }
  }
}

.help__field {
  &-model{
    max-width: 450px !important;
    margin: auto;
    padding: 1.5rem 1rem;
    background-color: var(--ra-color-white) !important;
    border-radius: 1rem !important;
  }
  &-desc {
    padding: 0.5rem 0 0 !important;
  }
}
.event{
  &__email{
    &-btn{
      margin-bottom: 1rem;
    }
    &-timezone{
      margin: 1.25rem -12px !important;
    }
    &-input{
      margin-bottom: 1.25rem;
    }
    &-actionbtn{
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
  }
  &__analytics{
    &-table{
      padding: 0.5rem 1rem 0 1rem;
    }
  }
}
.disabled-event-types {
  opacity: 0.4;
}

.timezone__country {
  &-field {
    .v-input__prepend-outer, .v-input__icon--prepend {
      display: none;
    }
  }
  &-chip {
    &--email {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}