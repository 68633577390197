.email {
  &-list {
    .v-list-group__items > .v-list-item {
      padding-left: 40px !important;
    }
  }

  &-table {
    td {
      @include respondMin(md) {
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 170px;
        overflow: hidden;
      }
    }

    th {
      min-width: 130px;
    }
  }
}

.emaillist{
  &__title {
    font-size: 1.2rem;
    @extend .fw-500;
    color: var(--ra-color-dark);

    &-spacing{
      margin-bottom: 1rem;
    }
  }

  &__btngroup{
    margin: 1rem 0;
    &-spacing{
      margin: 0 0.5rem 0.5rem 0;
    }
  }
}

.emaillogs{
  background-color: var(--ra-color-white);
  padding: 1rem;
  border-radius: 0.5rem;
  margin: 1rem 0;

  &__desc{
    font-size: 1rem;
    color: var(--ra-color-dark);
    margin-bottom: 0.5rem !important;
  }
}