// A map of breakpoints.
$breakpoints: (xs: 360,
  sm: 600,
  md: 960,
  lg: 1264,
  xl: 1904,
);

//
//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respondMin($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value+"px") {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  }

  @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respondMax($breakpoint) {

  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value+"px")) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  }

  @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//
//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respondBetween($lower, $upper) {

  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint"px") and (max-width: ($upper-breakpoint+"px")) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  }

  @else {

    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower)==false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper)==false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin font-size($size, $font-size) {
  #{$size} {
    font-size: $font-size;
  }
}

@mixin font-weight($weight, $font-weight) {
  #{$weight} {
    font-weight: $font-weight;
  }
}

@mixin d-flex($d-flex:flex, $justify:null, $align:null) {
  display: $d-flex;
  justify-content: $justify;
  align-items: $align;
  @content;
}
@mixin Dark-blur($color:null){
  background-color: $color !important;
  backdrop-filter: saturate(180%) blur(120px); 
  @content;
}