.ra{
  &__teammembers{
    padding: 1rem;
    margin-top: 1rem;
    &.theme--dark {
      background-color: var(--ra-color-offwhite1) !important;
    }
    &-img {
      width: 70px;
      height: 70px;
      margin-right: 1rem;
        .v-image {
        width: 70px;
        height: 70px;
        border-radius: 50% !important;
      }
    }
    &-title {
      font-size: 1.2rem;
      @extend .fw-600;
    }
    &-subtitle {
      font-size: 1rem;
      color: var(--ra-color-grey);
      margin: 1.5rem 0 1rem;
    }
    &-link {
      font-size: 1rem;
      color: var(--ra-color-primary);
      margin: 0.25rem 0 !important;
      word-break: break-word;
    }
    &-desc {
      font-size: 0.975rem;
      color: var(--ra-color-grey);
      margin-bottom: 0 !important;
    }
    &-btn {
      margin-left: auto;
      margin-top: 0.5rem;
      @include respondMin(sm){
        margin-top: 0;
      }
    }
    &-invite{
      color: $color-iframe;
    }
    &-request{
      color: $color-green2;
    }
    &-pointer{
      cursor: pointer;
    }
  }
}

.event-tooltip {
  z-index: 70;
}

.revoke {
  &__edit {
    margin-bottom: 0 !important;
    color: $color-red !important;
    cursor: pointer;
  }
} 