@import "../abstract/mixins";

.v-main {
  padding: 70px 0 0 !important;
}

[class*="col-"] {
  padding: 0px 12px !important;
}

.row {
  margin: 0 -12px !important;
}

// Sidedrawer Icon
.sidedrawer-icon {
  width: 30px !important;
  height: 30px !important;
  min-width: 30px !important;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.w-100{
  width: 100% !important;
}

.opacity-0{
  opacity: 0 !important;
}

//Pagination
.pagination__custom{
  .v-pagination .v-pagination__item--active{
    background-color: var(--ra-color-primary) !important;
  }
  .v-application .primary {
    background-color: var(--ra-color-primary) !important;
    border-color: var(--ra-color-primary) !important;
  }
}

.ra{
  &__body{
    background-color: var(--ra-color-offwhite1);
    min-height: 100vh;
  }

  &__card{
    background-color: var(--ra-lobby-schedule_container_colour) !important;
  }

  &__input{
    .v-input__slot{
      background-color: var(--ra-color-offwhite) !important;
    }
  }
  &__blurbg{
    &-1{
    @include Dark-blur(var(--ra-color-darklight));
    }
    &-2{
      @include Dark-blur(var(--ra-color-offgrey));
    }
  }

  &__datatable {
    td {
      padding: 0.75rem;
      @include respondMin(md) {
        min-width: 130px;
      }
    }
  }
}

.event{
  &__table{
    @extend .ra__blurbg-1;
    &-message { 
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 4;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      height: auto !important; 
      @include respondMin(md) {
        min-width: 200px !important;
        padding: 0.25rem 0;
      }
    }
  }
  th{
    white-space: nowrap !important;
  }
}

.v-application .primary--text {
  color: $color-primary !important;
  caret-color: $color-primary !important;
}

.v-divider {
  margin: 0.75rem 0 !important;
}

// Vue Editor
.ql-toolbar.ql-snow, .ql-container.ql-snow{
  border: 1px solid var(--ra-color-offwhite) !important;
}

// Coming Soon
.comingsoon {
  text-align: center;
  margin-top: 4rem;
  &__modal {
    margin-top: 0;
  }
  &__spacing {
    .comingsoon {
      margin: 1rem 0 2rem !important;
    }
  }
  &__img {
    margin: auto;
  }
  &__title {
    margin-top: 2rem;
    color: var(--ra-color-dark);
    line-height: 1.4;
  }
  &__subtitle{
    font-size: 1rem;
    margin-top: 1rem;
  }
}

/* intercom  */
.intercom-lightweight-app-launcher{
  @include respondMax(md){
    bottom: 64px !important;
  }
}

.welcome{
  &__title {
    font-size: 85px;
    margin-bottom: 1.5rem;
  }
}

.overlay {
  &__global {
    &-main {
      z-index: 501 !important;
      & .loader-img{
          max-width: 350px;
      }
    }
  }

}

// Intercom
.intercom-lightweight-app {
  z-index: 80 !important;
  &-launcher {
    @include respondMax(md) {
      width: 48px !important;
      height: 48px !important;
    }
    &-icon {
      @include respondMax(md) {
        width: 48px !important;
        height: 48px !important;
      }
      &-open svg {
        @include respondMax(md) {
          width: 28px !important;
          height: 22px !important;
        }
      }
    }
  }
}

// Popup
.v-dialog > .v-card > .v-card__title {
  word-break: break-word !important;
}

// BG
.bg-transparent {
  background-color: transparent !important;
}