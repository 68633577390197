.archive {
  &__event {
    // &-card {
    //   margin-bottom: 1.5rem;
    // }

    &-link {
      color: $color-primary !important;
    }
  }
}
