.adminview {
    &-btn {
        margin-bottom: 1rem;
    }
    &-form {
        background-color: var(--ra-color-white);
        padding: 2rem 2rem 0.25rem 2rem;
        border-radius: 0.5rem;
        @include respondMax(sm) {
            padding: 1.5rem 1.5rem 0.25rem 1.5rem;
        }
        &--spacing {
            margin-top: 2rem;
        }
        &--data {
            margin-bottom: 0.5rem !important;
        }
        &--img {
            border-radius: 0.5rem;
            margin: 0.75rem 0; 
        }
        &--check {
            margin-bottom: 1.5rem;
        }
    }
    &-help {
        .v-input--selection-controls__input {
            margin-bottom: 1.65rem;
        }
    }
    &-status {
        @extend .fw-500;
        margin-top: 1rem;

        &--success {
            color: $color-green2;
        }
        &--fail {
            color: $color-red;
        }
    }
    &-pills {
        margin-top: 1rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    &-pill {
        padding: 0.75rem 1.25rem;
        margin-right: 1rem;
        display: inline-block;
        border-radius: 0.5rem;
        @extend .fw-500;

        &-1 {
            color: $color-green2;
            background-color: rgba($color-green2, 0.1);
        }
        &-2 {
            color: $color-offorange;
            background-color: rgba($color-offorange, 0.1);
        }
        &-3 {
            color: $color-yellow;
            background-color: rgba($color-yellow, 0.1);
        }
        &-4 {
            color: $color-offpink;
            background-color: rgba($color-offpink, 0.1);
        }
        &-5 {
            color: $color-red;
            background-color: rgba($color-red, 0.1);
        }
        &-video {
            background-color: $color-prv;
        }
        &-stream {
            background-color: $color-es;
        }
        &-standard {
            background-color: $color-rs;
        }
        &-html {
            background-color: $color-html;
        }
        &-rtmp {
            background-color: $color-rtmp;
        }
        &-iframe {
            background-color: $color-iframe;
        }
    }

}