.report {
  margin: 2rem 0;

  table,
  th,
  td {
    border: 1px solid white;
    border-collapse: collapse;
    border-radius: 0.5rem;
  }
  th {
    font-weight: 500;
  }
  th,
  td {
    background-color: #f2f2f2;
    padding: 0.5rem 1rem;
  }

  &__card {
      border-radius: 0.5rem;
      padding: 1rem;
      margin-bottom: 1rem;
      box-shadow: 0 10px 40px rgba($color: #000000, $alpha: 0.07);
      &-data {
          margin: 0.5rem;
      }
  }

  &__footer { 
      &-data {
          padding: 0.5rem;
          margin: 0 !important;
      }
      &-dot {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #d5d5d5;
          margin: 0.5rem;
      }
  }
}
.sales-table {
  & table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    text-align: center;
    th, td {
      border: 1px solid $color-offgrey;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      min-width: 100px;
    }
    th {
      @extend .fw-500;
    }
    td {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      table.sales-inner-table {
        width: 100%;
        tr {
          border-bottom: 1px solid $color-offgrey;
        }
        & tr:last-child {
          border-bottom: 0;
        }
        td {
          border: none !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}
