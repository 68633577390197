.poll {
    &__subtitle {
      font-size: 1.25rem;
      @extend .fw-600;
      margin: 1.25rem 0;
      color: var(--ra-color-dark);
    }
  
    &__btn {
      &-spacing {
        margin: 1.5rem 0.5rem 0 0;
      }
    }
  
    &__modal {
      &-body {
        padding: 1.5rem;
      }
  
      &-btnspacing {
        margin: 0.5rem 0;

        &--cancel{
          margin: 0.5rem;
        }
      }
    }
  }

.userpages {
  &__header{
    display:flex;
    align-items:center;
    justify-content:space-between;
  }
}
  