.ra {
  &__form-heading {
    background-color: var(--ra-color-offwhite);
    padding: 1.5rem 1.8rem; //24px 28.8px
    position: sticky;
    top: 0;
    z-index: 9;

    @include d-flex {
      justify-content: space-between;
      align-items: center;
    }

    .v-card__title {
      word-break: break-word !important;
    }
  }
  &__form-subheading{
    padding: 1.5rem 1.8rem;
    @extend .fw-500;
  }

  &__label{
    &-block{
      display: flex;
      align-items: center;
    }
    &-icon{
      margin-bottom: 0.5rem;
      margin-left: 0.25rem;
      &--title{
        margin-left: 0.25rem;
        color: inherit !important;
      }
    }
  }

  & .v-card__title,
  &__modal-title {
    padding: 0 !important;

    &--heading {
      font-size: 1.5rem;
      @extend .fw-600;

      @include respondMax(md){
        font-size: 1.125rem;
      }
    }
  }

  &__fields-label {
    font-size: 1rem; //16px
    margin-bottom: 0.5rem; //8px
    display: block;
    @extend .fw-500;

    &--required{
      &::after{
        content: "*";
        font-size: 1.2rem;
        color: red;
      }
    }
  }

  &__expo-title {
    margin-bottom: 0.5rem; //8px

    @include d-flex {
      justify-content: space-between;
      align-items: center;
    }
  }

  &__schedule-expo {
    background-color: var(--ra-color-offwhite) !important;
    margin-bottom: 1rem; //16px
    padding: 1rem; //16px

    & .v-subheader {
      padding: 0 !important;
      height: auto;
    }
  }

  &__action-btn {
    margin: 1.125rem 0; //20px 0
    width: 100%;
    button{
      @include respondMax(md) {
        margin-bottom: 1rem;
      }
    }
    &--spacing{
      padding: 2rem 0 !important;
    }
  }

  &__add-button {
    margin-bottom: 0.5rem; //8px
  }

  &__waiting-popup{
    .confetti {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .confetti-piece {
        position: absolute;
        width: 10px;
        height: 30px;
        background: #ffd300;
        top: 0;
        opacity: 0;
          &:nth-child(1) {
            left: 7%;
            -webkit-transform: rotate(-40deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 182ms;
            -webkit-animation-duration: 1116ms;
          }
          &:nth-child(2) {
            left: 14%;
            -webkit-transform: rotate(4deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 161ms;
            -webkit-animation-duration: 1076ms;
          }
          &:nth-child(3) {
            left: 21%;
            -webkit-transform: rotate(-51deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 481ms;
            -webkit-animation-duration: 1103ms;
          }
          &:nth-child(4) {
            left: 28%;
            -webkit-transform: rotate(61deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 334ms;
            -webkit-animation-duration: 708ms;
          }
          &:nth-child(5) {
            left: 35%;
            -webkit-transform: rotate(-52deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 302ms;
            -webkit-animation-duration: 776ms;
          }
          &:nth-child(6) {
            left: 42%;
            -webkit-transform: rotate(38deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 180ms;
            -webkit-animation-duration: 1168ms;
          }
          &:nth-child(7) {
            left: 49%;
            -webkit-transform: rotate(11deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 395ms;
            -webkit-animation-duration: 1200ms;
          }
          &:nth-child(8) {
            left: 56%;
            -webkit-transform: rotate(49deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 14ms;
            -webkit-animation-duration: 887ms;
          }
          &:nth-child(9) {
            left: 63%;
            -webkit-transform: rotate(-72deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 149ms;
            -webkit-animation-duration: 805ms;
          }
          &:nth-child(10) {
            left: 70%;
            -webkit-transform: rotate(10deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 351ms;
            -webkit-animation-duration: 1059ms;
          }
          &:nth-child(11) {
            left: 77%;
            -webkit-transform: rotate(4deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 307ms;
            -webkit-animation-duration: 1132ms;
          }
          &:nth-child(12) {
            left: 84%;
            -webkit-transform: rotate(42deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 464ms;
            -webkit-animation-duration: 776ms;
          }
          &:nth-child(13) {
            left: 91%;
            -webkit-transform: rotate(-72deg);
            -webkit-animation: makeItRain 1000ms infinite ease-out;
            -webkit-animation-delay: 429ms;
            -webkit-animation-duration: 818ms;
          }
          &:nth-child(odd) {
            background: #7431e8;
          }
          &:nth-child(even) {
            z-index: 1;
          }
          &:nth-child(4n) {
            width: 5px;
            height: 12px;
            -webkit-animation-duration: 2000ms;
          }
          &:nth-child(3n) {
            width: 3px;
            height: 10px;
            -webkit-animation-duration: 2500ms;
            -webkit-animation-delay: 1000ms;
          }
          &:nth-child(4n-7) {
            background: red;
          }
          @-webkit-keyframes makeItRain {
            from {opacity: 0;}
            50% {opacity: 1;}
            to {-webkit-transform: translateY(350px);}
          }
      }
    }
    padding: 2rem 1rem 0 !important;
    text-align: center;
    .flip-card__top, .flip-card__bottom, .flip-card__back::before, .flip-card__bottom::after, .flip-card__back-bottom::after{
      color: $color-primary !important;
      background: var(--ra-color-offwhite) !important;
    }
    .flip-card__bottom, .flip-card__back-bottom, .flip-card__bottom-4digits, .flip-card__back-bottom-4digits{
      border-top: solid 1px var(--ra-color-offgrey) !important;
    }
    .flip-card__bottom, .flip-card__back-bottom, .flip-card__bottom-4digits, .flip-card__back-bottom-4digits{
      background: var(--ra-color-offwhite) !important;
    }
  }

  &__modal-body{
    padding: 1.5rem !important;

    &--img{
      border-radius: 50%;
      @include respondMin(md){
        margin-right: 1rem;
      }
      @include respondMax(md){
        margin: 1rem auto;
      }
    }

    &--img1{
      margin: 1.5rem auto 1rem;
    }

    &--headline{
      font-size: 1.3rem;
      @extend .fw-600;
      color: var(--ra-color-dark);
      margin-bottom: 0.5rem;
    }

    &--tagline{
      margin-bottom: 0.25rem !important;
    }

    &--message{
      font-size: 1.125rem;
      color: var(--ra-color-grey);
      line-height: 1.5;
      margin-bottom: 0.5rem;

      .message-link{
        color: $color-primary !important;
      }
    }

    &--subheadline{
      font-size: 1rem;
      @extend .fw-500;
      color: var(--ra-color-dark);
    }

    &--btngroup{
      margin-bottom: 1rem;

      & .btn-spacing{
        margin-right: 0.5rem;
      }
    }
  }
  &__copy-box{
    position: relative;
    .v-input{
      input{
        max-width: 85% !important;
        overflow-x: scroll;
        // text-overflow: ellipsis !important;
        @include respondMax(md){
          max-width: 70% !important;
        }
      }
    }

    .copy__btn{
      position: absolute;
      top: 40px;
      right: 15px;
      @include respondMax(md){
        bottom: 40px;
        top: inherit;
      }
    }

    .copy__btn-script{
      position: absolute;
      top: 106px;
      right: 15px;
      @include respondMax(md){
        bottom: 40px;
        top: inherit;
      }
    }

    .copy__btn-div{
      position: absolute;
      top: 74px;
      right: 15px;
      @include respondMax(md){
        bottom: 40px;
        top: inherit;
      }
    }
  }
}

.event-modal__section-title {
  font-size: 1.125rem; //20px
  @extend .fw-600;
  padding: 1.2rem 1rem; //16px 0
  margin: 2rem 0 1rem 0; //8px 0
  background-color: $color-offprimary;
  border-radius: 0.25rem;
}

.forms__event {
  max-width: 900px !important;
  &-fluid{
    max-width: 100% !important;
  }
}

.section {
  &__title {
    &-1 {
      background-color: $color-offgreen;
      color: $color-white;
    }

    &-2 {
      background-color: $color-offlightgreen;
      color: $color-white;
    }

    &-3 {
      background-color: $color-offlightorange;
      color: $color-white;
    }

    &-4 {
      background-color: $color-offorange;
      color: $color-white;
    }

    &-5 {
      background-color: $color-offpink;
      color: $color-white;
    }

    &-6 {
      background-color: $color-iframe;
      color: $color-white;
    }

    &-7 {
      background-color: $color-html;
      color: $color-white;
    }
    &-8 {
      background-color: $color-prv;
      color: $color-white;
    }
    &-9 {
      background-color: $color-rtmp;
      color: $color-white;
    }
    &-10 {
      background-color: $color-rs;
      color: $color-white;
    }
  }
}

.forms__event {
  max-width: 900px !important;
}
//multiple media
.multiplemedia__btn{
  margin-bottom: 0.5rem;
}

// draggable component
.move_obj {
  cursor: pointer;
}

.sortable-chosen {
  background-color: var(--ra-color-bggrey) !important;
  padding: 0.5rem 0.5rem;
}

// Attendees
.attendees{
  &__main{
    margin: 1rem 0;

    &-title{
      font-size: 1.25rem;
      @extend .fw-600;
      color: var(--ra-color-dark);
    }
  }

  &__block{
    margin: 1.5rem;

    &-title{
      font-size: 1.25rem;
      @extend .fw-600;
      margin-bottom: 0.5rem !important;
    }

    &-desc{
      color: var(--ra-color-dark);
      margin-bottom: 0.25rem !important;
      &--danger {
        color: $color-red;
      }
    }

    &-btngroup{
      margin: 1.5rem 0;

      & .btn__spacing{
        margin: 0.5rem;
      }
    }
  }
}
.eventhost-error {
  margin: -12px 0px 6px 0px;
}
.event{
  &__color{
    &-group{
      margin-bottom: 1rem;
    }
    &-box{
      cursor: pointer;
      height: 30px;
      width: 30px;
      border: 1px dashed $color-grey;
      border-radius: 0.25rem;
    }
  }
}

// manage attendee button
.ticket__details-attendees {
  text-align: right;
  margin-bottom: 1rem;
}

// Welcome modal
.welcome {
  &__modal {
    &-btngroup {
      margin: 0 auto 1rem;
      &--spacing {
        justify-content: center;
        padding-bottom: 1.5rem !important;
      }
    }

    &-img {
      padding: 16px 20px 0 20px;
      margin-bottom: 12px;
    }
    &-headline {
      font-size:1.125rem;
      @extend .fw-500;
      color: var(--ra-color-dark);
    }
  }
}
.errorMsg{
  color: red;
}

.timezone {
  &__description {
    text-align: center;
    font-size: 1rem;
    &--heading {
      font-size:1.25rem;
      @extend .fw-600;
      padding: 1rem 0;
    }
  }

  &__country{
    margin-left: 0.5rem;
  }
}
.event__table-csv{
  .vue-csv-uploader{
    &-part-one{
      margin-bottom: 1.5rem;

      .csv-import-checkbox{
        margin-bottom: 1.125rem;
      }
      .csv-import-file{
        margin-bottom: 1.125rem;
      }
      .form-group{
        input.btn{
          height: 44px;
          min-width: 78px;
          padding: 0 19.5555555556px;
          font-size: 0.875rem;
          border-radius: 0.25rem;
          @extend .fw-500;
          letter-spacing: 0.0892857143em;
          white-space: nowrap;
        }
      }
    }
    &-part-two{
      .vue-csv-mapping{
        margin-bottom: 1.5rem;
          table{
            width: 100%;
            padding-bottom: 1.25rem;
            thead{
              tr{
                th{
                  text-align: left;
                  padding-bottom: 1rem;
                }
              }
              @include respondMax(md){
                display: none;
              }
            }
            tbody{
              tr{
                td{
                  select{
                    background-color: $color-offwhite2;
                    padding: 0.75rem;
                    border-radius: 0.0025;
                    border-radius: 0.5rem;
                    margin: 0.75rem 0 !important;
                    -webkit-appearance: auto;
                    @include respondMax(md){
                      width: 100%;
                    }
                  }
                  @include respondMax(md){
                    width: 100%;
                    display: block;
                  }
                }
              }
            }
          }
      }
    }
  }
  .v-data-table-header{
    white-space: nowrap !important;
  }
  .attendance-submit{
    margin-top: 1.5rem;
  }
  .csv_head{
    text-align: justify;
  }
}

.model{
  &__width{
    max-width: 700px !important;
    margin: auto;
  }
}

.rating{
  &__modal-body{
    padding: 1.5rem 1.8rem 0 !important;
    text-align: center;
  }
  &__input{
    .v-input__control {
      .v-input__slot {
        justify-content: center;
      }
      .v-messages {
        text-align: center !important;
      }
    }
  }
  &__star{
    margin: 1rem 0;
  }
  &__btn-spacing{
    padding-bottom: 2rem !important;
    justify-content: center;
  }
  &__img{
    max-width: 200px;
    margin: 0 auto 1rem;
  }
}
.message{
  &__model {
    &-container {
      background-color: var(--ra-color-offwhite);
      border-radius: 0.5rem;
      padding: 1.25rem !important;
      margin-bottom: 1rem;
    }
    &-title{
      margin-bottom: 0.5rem;
      @extend .fw-500;
      color: var(--ra-color-dark);
      font-size: 1.25rem;
    }
    &-desc {
      margin: 1rem 0;
    }
    &-date {
      margin: 0 !important;
      text-align: right;
    }
  }
}

// Payment
.credit__card {
  &-header {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  &-form {
    padding: 2rem 0;
    border-radius: 0.5rem;
    background-color: var(--ra-color-offwhite1);
    margin-bottom: 2rem !important;
    &--inner {
      text-align: center;
    }
  }
  &-details {
    margin-top: 1.5rem;
  }
  &-alert {
    text-align: center;
    &--title {
      margin-bottom: 1.5rem;
      @extend .fw-500;
    }
  }
  &-banner {
    margin-top: 0.5rem;
  }
  &-desc {
    margin: 0 !important;
  }
  &-stripe {
    max-width: 80px !important;
    margin-left: 0.5rem;
  }
}

.StripeElement {
  margin: 1rem 0 2rem;
}
