.help__modal {
    border-radius: 0.5rem !important;
    &-head {
        padding: 1.5rem 1.5rem 3rem;
        background-color: var(--ra-color-primary) !important;
        color: $color-white !important;
        .v-btn--icon {
            color: $color-white !important;
        }
        .help__modal-title {
            color: $color-white !important;
        }
    }
    &-title {
        font-size: 1.25rem;
        @extend .fw-500;
        color: var(--ra-color-dark);
        margin-bottom: 0.5rem;
    }   
    &-body {
        padding: 1.25rem;
        margin-top: -2rem;
        background-color: var(--ra-color-white) !important;
        border-radius: 0.5rem;
        box-shadow: 0 0.5rem 1rem rgba($color: #000, $alpha: 0.08) !important;
    }
    &-option {
        text-align: center;
        cursor: pointer;
        border-radius: 0.5rem;
        padding: 0.75rem 0;
        &:hover {
            background-color: var(--ra-color-offprimary);
        }
        &--img {
            max-width: 40px !important;
            margin: auto;
        }
        &--title {
            padding-top: 0.5rem;
            margin-bottom: 0 !important;
        }
        &--title2 {
            padding: 0.5rem 0;
        }
    }
    &-action {
        padding: 1.5rem 1rem 1rem;
        background-color: var(--ra-color-white) !important;
        border-radius: 0.5rem;
        box-shadow: 0 0.5rem 1rem rgba($color: #000, $alpha: 0.08) !important;
        margin-top: 1.25rem;
        cursor: pointer;
        border-top: 4px solid $color-primary;
    }
}