.subscription{
    padding: 0 !important;
    &__title{
        font-size: 1.125rem;
        @extend .fw-500; 
        color: var(--ra-color-dark) !important;
        &-data{
            color: var(--ra-color-primary) !important;
        }
    }
    &__desc{
        font-size: 1rem;
        color: var(--ra-color-dark) !important;
        margin: 0.5rem 0 1rem !important;
    }
    &__total{
        text-align: center;
        padding: 2rem 1rem !important;
    }
    &__card{
        padding: 1rem;
        background-color: var(--ra-color-offwhite1) !important;
        margin-bottom: 1.5rem;
        &-title{
            font-size: 1.25rem;
            @extend .fw-600;
            color: var(--ra-color-dark);
            justify-content: center;
            padding: 0 !important;
        }
        &-text{
            padding-bottom: 0 !important;
        }
        &-data{
            font-size: 1rem !important;
            &--black{
                font-size: 1rem !important;
                @extend .fw-500;
                color: var(--ra-color-dark) !important;
                margin-bottom: 0 !important;
            }
        }
    }
    &__add-payment{
        &--btn{
            margin-top: 1rem;
        }
    }
}

.alert{
    &__message{
        background-color: var(--ra-color-offwhite1);
    }
    &__title{
        font-size: 1rem;
        @extend .fw-500; 
        color: var(--ra-color-dark) !important;
        margin-bottom: 0.25rem;
    }
    &__desc{
        font-size: 0.85rem;
        margin-bottom: 0 !important;
    }
}