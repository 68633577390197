.tab-toolbar,
.tabs-items, .v-tabs-bar, .v-tabs-items {
    background-color: transparent !important;
}

.v-tab--active{
    color: $color-primary !important;
}

.v-tabs-slider {
    background-color: $color-primary !important; 
}

.tabs__schedule{
    .v-slide-group__wrapper{
        justify-content: center !important;
    }
    .v-slide-group__content{
        flex: 0 auto !important;
    } 
    .v-tab {
        margin-left: 0 !important;
    } 
    &-timezone {
        min-height: 150px;
    }
}