.networking {
  background-color: var(--ra-color-offwhite1);

  &__side-panel {
    padding: 1rem 0 5rem;

    &--mob {
      background-color: var(--ra-color-white);
      padding: 0 1rem;
      border-radius: 0.5rem;
    }

    &--switchlabel {
      margin-bottom: 0 !important;
      padding: 0 1rem 0.25rem 0;
    }

    @include respondMin(md) {
      max-height: 100vh;
      overflow: auto;
      position: sticky;
      width: 100%;
      top: 70px;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
    &--broadcast {
      width: 100%; 
      padding: 1rem 1.5rem; 
      box-sizing: border-box; 
      background-color: var(--ra-lobby-schedule_container_colour); 
      border-radius: 0.5rem;
      
      @include respondMax(md){
        padding: 0;
        margin-bottom: 1rem;
      }

      #stream_status {
        font-size: 12px;
        @extend .fw-600;
        text-align: center;
        width:100%;
        color: var(--ra-color-dark);
      }
    } 
    &--role, &--status{
      color: var(--ra-lobby-main_font_colour);
    }
    .v-expansion-panels .v-expansion-panel {
      background-color: var(--ra-lobby-schedule_container_colour) !important;
      .v-expansion-panel-header {
        background-color: var(--ra-lobby-sidebar_unselected_colour) !important;
        color: var(--ra-lobby-sidebar_unselected_font_colour) !important;
        &--active {
          background-color: var(--ra-lobby-sidebar_selected_colour) !important;
          color: var(--ra-lobby-sidebar_selected_font_colour) !important;
        }
      }
    }
  }

  &__header {
    height: 44px;

    .v-badge__badge {
      top: -7px !important;
      left: -16px !important;
    }
  }

  .v-expansion-panel {
    border-radius: 0.25rem;
    &::before {
      box-shadow: none !important;
    }

    &-header {
      padding: 16px 12px !important;
      border-radius: 0.25rem;

      &__icon {
        .v-icon {
          color: currentColor !important;
        }
      }
    }

    &-content__wrap {
      padding: 0 0.75rem 0.75rem !important;
    }

    &:not(:first-child)::after {
      border-top: none !important;
    }

    &:not(.v-expansion-panel--active) {
      margin-top: 16px;
    }

    &-content {
      max-height: 500px;
      overflow: auto;
    }
  }

  .v-badge__wrapper {
    top: 10px !important;
    left: -10px !important;
  }

  &__desc {
    margin-top: 1.5rem;
  }

  &__btngroup {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
  }  

  &__room {
    &-card {
      .ra__carousel-card {
        background-color: var(--ra-lobby-schedule_container_colour) !important;
        margin-bottom: 1rem;
      }
    }
    &-body {
      padding: 0.75rem;
    }
    &-delete {
      position: absolute !important;
      top: 0.5rem;
      right: 0.5rem;
      background-color: var(--ra-color-offwhite) !important;
    }
    &-desc {
      padding-top: 0.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      height: 46px;
      font-size: 14px !important;
    }
    &-btn {
      margin-top: 0.75rem;
    }
    &-jitsi {
      .boxofsquare {
        max-height: 95px !important;
        display: flex;
        border: 1px solid var(--ra-color-offgrey);
        border-radius: 0.5rem;
        margin-top: 0.5rem;
        overflow: auto;
      }
      .squares {
        text-align: center;
        margin: 0.5rem;
        cursor: pointer;
      }
      .jitsi__label { 
        font-size: 0.75rem;
        display: block;
        white-space: nowrap !important;
        margin-top: 0.5rem;
      }
      .square-box {
        background-color: var(--ra-lobby-schedule_container_colour) !important;
        color: var(--ra-lobby-main_font_colour) !important;
        padding: 0.5rem 1rem;
        border-radius: 0.3rem;
        &-active {
          padding: 0.5rem 1rem;
          border-radius: 0.3rem;
          background-color: $color-red;
          color: var(--ra-lobby-main_font_colour);
        }
      }
    }
    &-people {
      display: flex;
      margin-bottom: 0.75rem;
      &--user {
        margin-right: -1rem;
        &:nth-child(1) {
          z-index: 18;
        }
        &:nth-child(2) {
          z-index: 17;
        }
        &:nth-child(3) {
          z-index: 16;
        }
        &:nth-child(4) {
          z-index: 15;
        }
        &:nth-child(5) {
          z-index: 14;
        }
        &:nth-child(6) {
          z-index: 13;
        }
        &:nth-child(7) {
          z-index: 12;
        }
        &:nth-child(8) {
          z-index: 11;
        }
        &:nth-child(9) {
          z-index: 10;
        }
        &:nth-child(10) {
          z-index: 9;
        }
        &:nth-child(11) {
          z-index: 8;
        }
        &:nth-child(12) {
          z-index: 7;
        }
        &:nth-child(13) {
          z-index: 6;
        }
        &:nth-child(14) {
          z-index: 5;
        }
        &:nth-child(15) {
          z-index: 4;
        }
        &:nth-child(16) {
          z-index: 3;
        }
        &:nth-child(17) {
          z-index: 2;
        }
        &:nth-child(18) {
          z-index: 1;
        }
        &:nth-child(19) {
          z-index: -1;
        }
        @include respondMax(md) {
          margin-right: -1.15rem;
        }
      }

      &--img, &--img .v-image {
        height: 40px !important;
        width: 40px !important;
        border-radius: 50% !important;
        border: 1px solid var(--ra-color-offwhite); 
      }
      &--empty {
        background-color: var(--ra-color-offgrey);
      }
    }
  }
}
.card__body {
  margin-top: 0.5rem;
}
.header {
  &__switch {
    margin-top: 0.5rem;
    &-label {
      margin-right: 1rem;
    }
  }
} 

.event-close-box {
  width: 100%;
  height: auto;
  padding: 1.5rem;
  border: 1px solid #c8c8c8;
  border-radius: 0.5rem;
  margin: 30px 0px;

  h3 {
    font-size: 1.5rem;
    margin-bottom: 40px;
  }
  p {
    font-size: 1rem;
  }
  span.cross-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    background-color: red;
    margin: 20px 0px;
    justify-content: center;
  }
}
#embed-player{
  margin-bottom: 4rem !important;
  @include respondMax(md){
    margin-bottom: 5rem !important;
  }
}
.player__video {
  width: 100%;
  position: relative;
  
  .boxofsquare {
    max-height: 95px !important;
    max-width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    border: 1px solid var(--ra-color-offgrey);
    border-radius: 0.5rem;
    display: flex;
    margin: auto;
    justify-content: center;
    text-align: center;
    @include respondMax(md){
      max-height: 100px !important;
    }
    @media (max-width: 420px) {
      justify-content: flex-start;
      padding-left: 0;
    }
    .squares {
      float: none !important;
      .square-box {
        display: flex;
        flex-direction: column;
        min-width: 80px;
        margin: 0 auto;        
        border-radius: 0.3rem;
        padding: 10px;
        color: var(--ra-lobby-main_font_colour);
        &-active{
          border-radius: 0.3rem;
          min-width: 80px;
          margin: 0 auto;
          padding: 10px;
          background-color: $color-red;
        }
      }
    }
  }
}
.theme--dark{
  .player{
    &__video{
      .squares {
        .square-box {
          background-color: var(--ra-lobby-schedule_container_colour);
          color: var(--ra-lobby-main_font_colour);
        }
      }
    }
  }
}
#stream_status{
  span.green{
    color: $color-dark !important;
  }
  span.red{
    color: $color-white !important;
  }
}
.jitsi {
  &__label {
    margin-top: 0.5rem;
  }
}

.session{
  &__stage{
    &-video{
      .vp-video-wrapper{
        background-color: #1a2e3b;
      }
    }
  }
  &__divide-box{
    width: 100%;
    margin: 1.5rem 0;
  }
  &__pdf-box{
    .v-image{
      margin-bottom: 1.5rem;
      .v-image__image{
        background-position: top left !important;
      }
    }
  }
}

#stream_status{
  font-size: 12px;
  @extend .fw-600;
  text-align: center;
  width:100%
}
.yourrole{
  font-size: 12px;
  @extend .fw-600;
  text-align: center;
  width:100%
}
#blue_status{
  font-size: 14px;
  @extend .fw-600;
  background-color: #6d9eeb;
  color: white;
  text-align: center;
  padding: 10px;
  width:100%
}
.part{
  padding: 20px;
  border: 1px solid #000000;
  background-color: var(--ra-lobby-schedule_container_colour);
}
.part_presenter{
  padding: 20px
}
.new_video_position{
  position: fixed;
  top: 80px;
  right: 0px !important;
  z-index: 3;
  transition: all .5s ease-in;
}
.anim{
  transition: all .5s ease-in;
}
.close-bar{
  height: 35px; 
  background-color: #e0e0e0;
  transition: all .5s ease-in;
}

#networkingVideoContainer{
  position: relative;
}

// Invite to Stream
.invite__stream {
  background-color: var(--ra-color-offwhite); 
  border-radius: 0.5rem;
  padding: 0.75rem;
  margin-bottom: 1rem;
  &-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
  }
  &-heading {
    margin: 0 0.5rem;
  }
  &-title {
    font-size: 1rem;
    margin-bottom: 0.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
  &-subtitle {
    font-size: 0.8rem;
    color: var(--ra-color-dark);
    margin: 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical; 
  }
  &-btn {
    margin-left: auto;
  }
}

// Chat Envelop
.chat__envelope {
  max-height: 64vh;
  &-attendee {
    min-height: 40px !important;
    .v-menu__content--fixed {
      max-height: 64vh;
    }
  }
}

// Networking Roulette
.roulette {
  &__block {
    margin-bottom: 0.25rem;
    @include respondMax(md) { 
      margin-bottom: 0.5rem;
    }
    &-spacing {
      padding: 0 1.5rem 0.5rem;
    }
    &-title {
      font-size: 1.125rem;
      @extend .fw-600;
    }
    &-img {
      width: 40px !important;
      height: 40px !important;
      border-radius: 50%;
      margin-right: 0.75rem;
      .media-display__profile-wrap {
        width: 40px !important;
        height: 40px !important;
        .media-display__profile-photo {
          width: 40px !important;
          height: 40px !important;
        }
      }
    }
    &-name {
      font-size: 1rem;
      @extend .fw-500;
    }
    &-desc {
      font-size: 0.975rem;
    }
    &-icon {
      color: $color-primary !important;
    }
    &-popup {
      &--body {
        max-height: 70vh;
        overflow: auto;
      }
    }
    &-input {
      .v-text-field__details {
        display: none !important;
      }
    }
  }
}
// Customize Tooltip 
.v-tooltip__content {
  opacity: 1 !important;
  background: $color-grey !important;
}

//Roulette Flying Emoji

#icons_set{
  position: absolute;
  margin-top: -50px;
  width: 100%;
} 

.icon_move {position: absolute;
  font-size: 24px;
  line-height: 32px;
  width: 32px;
  height: 32px;
  top: 0;
  left: 20px;
  opacity: 0;
  z-index: 1
}

.icon_move_1 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 20px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}
.icon_move_2 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 40px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}
.icon_move_3 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 60px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}
.icon_move_4 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 80px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}
.icon_move_5 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 100px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}
.icon_move_6 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 120px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}
.icon_move_7 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 140px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}
.icon_move_8 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 160px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}
.icon_move_9 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 180px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}
.icon_move_10 {line-height: 32px;width: 32px;height: 32px;top: 0;left: 200px;opacity: 0;z-index: 1;position: relative;margin-top: -75px;font-size: xxx-large;}

.start_1{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}
.start_2{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}
.start_3{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}
.start_4{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}
.start_5{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}
.start_6{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}
.start_7{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}
.start_8{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}
.start_9{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}
.start_10{animation: flow-to-right 5s forwards ease-in-oute;animation: animation-1 5s forwards ease-in-out;}

@keyframes flow-to-right {
  0% {
      transform: translate(0,0) scale(.6);
      opacity: 1
  }

  70% {
      transform: translate(40px,-70vh) scale(1.5);
      opacity: 1
  }

  75% {
      transform: translate(40px,-70vh) scale(1.5);
      opacity: 1
  }

  100% {
      transform: translate(140px,-50vh) scale(1);
      opacity: 0
  }
}
@keyframes animation-1 {
  0% {
      transform: translate(0,0) scale(.6);
      opacity: 1
  }

  70% {
      transform: translate(8.4404081746px,-42.4329379563vh) scale(1.5);
      opacity: 1
  }

  75% {
      transform: translate(8.4404081746px,-42.4329379563vh) scale(1.5);
      opacity: 1
  }

  100% {
      transform: translate(163.1531832708px,-42.4113336236vh) scale(1);
      opacity: 0
  }
}
