.analytics{
    &__title {
        font-size: 1.25rem;
        @extend .fw-600;
        margin-bottom: 1.25rem;
        color: var(--ra-color-dark);
    }
    &__modal{
        &-spacing{
            padding: 1rem !important;
        }
    }
    &__spacing {
        margin-bottom: 1rem;
    }
}

// Financials
.financials {
    &__heading {
        background-color: var(--ra-color-offwhite);
        margin-top: 2rem;
        padding: 1rem;
        border-radius: 0.5rem;
        text-align: center;
        &-title {
            font-size: 1.2rem;
            @extend .fw-600;
            color: var(--ra-color-dark);
        }
        &-subtitle {
            margin: 1rem 0 0.5rem;
            @extend .fw-500;
            color: var(--ra-color-dark);
            text-align: center;
        }
    }
}