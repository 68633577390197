.dashboard-header {
  background-color: var(--ra-color-white) !important;
  padding: 0.5rem;
  z-index: 111 !important;
  &.theme--dark {
    @extend .ra__blurbg-1;
  }
  .spacer {
    @include respondMax(md) {
      display: none;
    }
  }
}

header.dashboard-header {
  box-shadow: 0 10px 30px rgba($color: #000000, $alpha: 0.08) !important;
}

header,
header .v-toolbar__content {
  height: auto !important;
} 

.dashboard-header--login {
  .v-toolbar__content {
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
}

.v-navigation-drawer__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include respondMax(md) {
    justify-content: center;
  }
}

.header {
  &__theme-switch {
    margin-right: 0.25rem;

    &--color{
      color: $color-primary !important;
    }
  }

  &__nav-link {
    font-size: 0.975rem !important;
    padding: 0.5rem; 
  }
  &__profile {
    .v-image__image, .media-display__profile-icon {
      border-radius: 50% !important; 
    }
  }

  &__icon {
    &-button {
      background-color: rgba($color-grey, 0.08);
      border-radius: 50%;
    }
  }
}

.v-application--wrap .dashboard-header .header__nav-link--btn{
  background-color: transparent !important;
  color: #1976d2 !important;
  box-shadow: none !important;
  padding: 0 8px !important;
  font-size: 0.975rem;
  font-weight: normal;
  min-width: 46px !important;
  letter-spacing: normal;
}

.side__drawer .side__drawer-menu .header__nav-link--btn{
  background-color: transparent !important;
  color: var(--ra-color-white) !important;
  box-shadow: none !important;
  padding: 0 !important;
  font-weight: normal;
  min-width: 48px !important;
}

// Overlay

.overlay__global {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 151;
  opacity: 0.9;

  .v-skeleton-loader__image {
    height: 100%;
  }
}

.header {
  &__login-btn {
    margin: 0 0.5rem;
  }
}
