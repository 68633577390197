.eventdetails {
  &__side-panel {
    padding: 1rem 1.125rem 1rem 1rem;
    @include respondMin(md) {
      max-height: 100vh;
      overflow: auto;
      position: sticky;
      width: 100%;
      top: 70px;
      padding-bottom: 6rem !important;

      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  &__sheet {
    background-color: var(--ra-lobby-schedule_container_colour);
    border-radius: 0.5rem;
    padding: 1rem;
    margin-top: 1rem;
    &-title {
      font-size: 1rem;
      @extend .fw-500;
      color: var(--ra-lobby-main_font_colour) !important;
    }
    .theme--light.v-btn.v-btn--disabled {
      color: var(--ra-color-offgrey) !important;
    }
  }
  &__ticket {
    &-title {
      font-size: 1.25rem; //20px
      @extend .fw-500;
      margin: 1rem 0; //16px
    }
    &-head{
      display: flex;
      align-items: flex-start;
      justify-content:space-between;
      margin-bottom: 0.5rem;
    }
    &-block {
      padding: 1rem 0; //16px
      border-bottom: 1px solid var(--ra-color-offgrey);

      .media-display{
        height: 40px;
        width: 60px;

        .v-image{
          border-radius: 0.5rem !important;
        }
      }

      &--modal {
        padding: 1rem 0; //16px
        border-bottom: 1px solid var(--ra-color-offgrey);
        .media-display{
          width: 120px;
          .v-image{
            border-radius: 0.5rem !important;
          }
        }
      }
    }
    &-subblock {
      width: 100%;
      padding-left: 0.5rem; //8px
    }
    &-subtitle {
      font-size: 1.25rem; //16px
      color: var(--ra-color-dark);
      @extend .fw-500;
    }
    &-desc, &-desc p {
      margin: 0.25rem 0 !important; //8px
      color: var(--ra-color-grey);
    }
    &-type {
      margin: 0 !important;
      color: var(--ra-color-grey);
    }
    &-btnaction{
      display: flex;
      justify-content: flex-end;
      flex-flow: column;
      padding-left: 0.875rem;
      &-label{
        display: block;
        text-align: right;
        color: var(--ra-color-red);
        white-space: nowrap;
        margin-top: 0.25rem;
      }
    }
  }
  &__cardblock {
    background-color: var(--ra-lobby-schedule_container_colour);
    border-radius: 0.5rem;
    @include respondMin(lg) {
      position: sticky;
      top: 155px;
    }
    &-header {
      padding: 0.85rem 1rem;
      border-radius: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &-title {
      font-size: 1.125rem;
      @extend .fw-600;
      text-align: center;
    }
    &-body {
      padding: 0 1rem;
      max-height: 64vh;
      overflow: auto;
      @include respondMin(xl) {
        max-height: 72vh;
      }
    }
  }
  &-profile {
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--ra-color-offwhite1);
    &--img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      @include respondMax(sm) {
        width: 48px;
        height: 48px;
      }
    }
    &--data {
      border-radius: 0.5rem !important;
      margin-top: 0.5rem;
      .v-list-item__action {
        margin-right: 1rem !important;
      }
    }
  }
  &__date {
    margin-top: 1rem;
    &-title {
      margin-bottom: 0.5rem;
      @extend .fw-600;
      font-size: 1.125rem;
      display: flex;
      align-items: center;

      .v-icon{
        padding-right: 0.25rem;
      }
    }
    &-icon {
      margin: 0 0.2rem;
    }
    &-info {
      font-size: 0.9rem;
      margin-bottom: 0.5rem !important;
    }
    &-time{
      font-size: 1rem;
      display: flex;
      align-items: center;

      .v-icon{
        padding-right: 0.25rem;
      }
    }
  }
  &__subtitle {
    font-size: 1.25rem;
    @extend .fw-600;
    margin: 0.75rem 0;
    &-block {
      color: var(--ra-lobby-main_font_colour) !important;
      font-size: 1.2rem;
      @extend .fw-600;
      margin: 1.5rem 0 1.5rem;
    }
  }
  &__subdesc {
    &.collapse {
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 4;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      & p:not(:first-child), ol, ul {
        display: none;
      }
    }
    &-html {
      line-height: 1.6;
    }
  }
  &__schedule {
    margin-bottom: 1rem; //16px
    padding: 1rem; //16px
    border-radius: 0.5rem; //8px
    background-color: var(--ra-lobby-schedule_container_colour);
    cursor: pointer;
    .v-image{
      border-radius: 0.5rem !important;
    }
    &-presentersbox{
      padding-top: 0.5rem;
    }
    &-title {
      font-size: 1rem;
      color: var(--ra-lobby-main_font_colour);
      @extend .fw-500;
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    &-desc, &-desc p {
      margin-bottom: 0.25rem !important;
      color: var(--ra-lobby-main_font_colour);
      overflow: hidden;
      text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size: 0.8rem;
    }
    .eventdetails__schedule-desc p:not(:first-child) {
      display: none;
    }
    &-date {
      margin-bottom: 0.5rem !important;
      font-size: 0.9rem;
      &--icon {
        font-size: 1.125rem !important;
        color: var(--ra-lobby-main_font_colour) !important;
      }
    }
    &-presenters {
      font-size: 0.975rem;
      @extend .fw-500;
      cursor: pointer;
      margin: 0.125rem 0.9rem 0.25rem 0 !important;

      figure {
        margin: 0 !important;
      }
      &--name {
        margin: 0 !important;
        padding-left: 0.5rem;
      }
      &--profile {
        width: 42px !important;
        height: 42px !important;
        .v-image,
        .v-image__image {
          border-radius: 50% !important;
          width: 42px !important;
          height: 42px !important;
        }
      }
      &--img .v-image {
        width: 96px !important;
        height: 96px !important;
        border-radius: 50%;
        @include respondMin(md) {
          width: 120px !important;
          height: 120px !important;
        }
      }
      &--head {
        height: 76px !important;
        align-items: flex-start !important;
        @include respondMin(md) {
          height: 84px !important;
        }
        .eventdetails__schedule-presenters--title {
          @include respondMax(md) {
            font-size: 1rem;
            overflow: hidden;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            white-space: nowrap;
          }
        }
      }
      &--title {
        @extend .fw-500;
        margin-left: 12px;
      }
      &--subtitle {
        margin-left: 12.5px !important;
      }
      &--desc {
        font-size: 1rem;
        padding-left: 136px;
        margin-bottom: 0 !important;
        @include respondMax(md) {
          padding-left: 0;
          margin-top: 24px;
        }
      }
    }
    &-modal {
      padding-top: 1.25rem !important;
      padding-bottom: 2.5rem !important;
      &--title {
        font-size: 1.125rem;
        color: var(--ra-color-dark);
        @extend .fw-500;
        margin-bottom: 0.25rem;
      }
      &--desc, &--desc p {
        margin-bottom: 0.5rem !important;
        color: var(--ra-color-grey);
        font-size: 0.975rem;
      }
      &--date {
        font-size: 1.125rem;
        margin-bottom: 0.5rem !important;
        &--icon {
          font-size: 1.5rem !important;
        }
      }
      &--attendees {
        padding-top: 0.5rem !important;
        padding-left: 135px !important;
        padding-bottom: 0.5rem !important;
        min-height: 75px;
        @include respondMax(md) {
          padding-top: 2.75rem !important;
          padding-left: 0 !important;
          min-height: 55px;
        }
      }
    }
  }
  &__group {
    .btn-outline-primary.v-btn.v-btn--disabled.v-btn--outlined.v-size--default {
      color: var(--ra-lobby-main_font_colour) !important;
      & i {
        &::before {
          color: var(--ra-lobby-main_font_colour) !important;
        }
      }
    }
    @include respondMax(lg) {
      padding-top: 0.5rem;
    }
    &-btn1 {
      margin-bottom: 0.5rem;
    }
  }
  &__btn {
    text-align: center;
    margin: 3rem 0 1.5rem;
  }
  &__people {
    background-color: var(--ra-lobby-schedule_container_colour) !important;
    border-radius: 0.5rem !important;
    transition: all 0.3s ease-out;
    margin-top: 1rem;
    &:hover {
      box-shadow: 0px 4px 13px rgb(0, 0, 0, 0.2) !important;
    }
    &-card {
      background: transparent !important;
    }
    &-title,
    &-subtitle {
      color: var(--ra-lobby-main_font_colour) !important;
      @extend .fw-500;
    }
    &-title {
      font-size: 1.125rem;
      margin-bottom: 0.5rem !important;
    }
    &-cardtitle {
      margin-bottom: 1rem !important;
      font-size: 1.125rem;
      @extend .fw-500;
    }
    &-body {
      padding: 1rem;

      & > p {
        margin-bottom: 0.5rem !important;
      }
    }
    &-btn {
      width: 100%;
      justify-content: start !important;
    }
  }
  &__event-btn-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
  }
  &__networking {
    &-box {
      margin: 1rem 0 0.5rem;
      background-color: var(--ra-lobby-schedule_container_colour);
      padding: 0.5rem;
      border-radius: 0.25rem;
      &:hover {
        box-shadow: 0px 4px 20px rgba(31, 30, 30, 0.2) !important;
      }
    }
    &-title {
      font-size: 1rem;
      color: var(--ra-lobby-main_font_colour);
      @extend .fw-600;
      margin: 0.5rem 0;
    }
    &-bubbles {
      margin: 0.5rem 0;
      &--list {
        font-size: 0.85rem;
      }
    }
    &-wrapper{
      max-height: 85px;
      overflow: auto;
    }
  }
  &__whitelabel {
    .v-image {
      max-height: 46px;
    }
    .v-responsive__content, .v-image__image {
      width: 200px !important;
      background-size: contain !important;
      @include respondMax(sm) {
        width: 180px !important;
      }
    }
  }
  &__schedule-contian{
    .v-image{
      border-radius: 0.5rem;
      .v-image__image{
        background-size: cover !important;
      }
    }
  }
  &__main-title{
    padding: 0 0.25rem;
    text-align: center;
    background-color: var(--ra-color-white);

    h1.eventdetails__main-titletext{
      padding-top: 0.5rem;
    }
  }
  &__schedule-title{
    color: var(--ra-lobby-main_font_colour) !important;
    @extend .fw-600;
  }
  &__main-header{
    position: sticky;
    top: 80px;
    left: 0;
    width: 100%;
    z-index: 2;
    background-color: var(--ra-color-white);

    @include respondMax(md){
      top: 70px;
      padding-top: 50px;
    }
  }

  &__normal-padding{
    top: 66px !important;
    @include respondMax(md){
      top: 64px !important;
    }
  }

  &__back-btn{
    position: absolute;
    top: 30px;
    left: 10px;
    z-index: 10;
    @include respondMax(md){
      top: 10px;
      left: 10px;
    }
  }
  &__scroll-btn{
    position: absolute;
    top: 30px;
    right: 10px;
    z-index: 10;

    @include respondMax(md){
      top: 10px;
      right: 10px;
    }
  }
  &__sponser {
    .v-image {
      border-radius: 0.25rem !important;
      max-height: 120px;
    }
  }
  &__imgradius{
    .v-image{
      border-radius: 0.5rem;
    }
  }
  &__public {
    &-img {
      .v-image {
        border-radius: 0.5rem;
      }
    }
  }
}

.schedule {
  &__filter {
    font-size: 1.25rem;
    @extend .fw-500;
    @extend .w-100;
    margin: 1rem 1rem 0 0;

    &-wrapper {
      margin-bottom: 1rem;
      & .v-slide-group__content {
        overflow: auto;
      }
    }

    &-pills {
      margin: 0.25rem !important;
    }
  }
}

.event__group-bg.v-chip.v-chip--outlined.v-chip.v-chip {
  color: $color-white !important;
  border: none !important;
  background-color: rgba($color: $color-rs, $alpha: 1) !important;
}
.event__networking-bg.v-chip.v-chip--outlined.v-chip.v-chip {
  color: $color-white !important;
  border: none !important;
  background-color: rgba($color: $color-html, $alpha: 1) !important;
}
.event__poster-bg.v-chip.v-chip--outlined.v-chip.v-chip {
  color: $color-white !important;
  border: none !important;
  background-color: rgba($color: $color-rtmp, $alpha: 1) !important;
}
.event__expo-bg.v-chip.v-chip--outlined.v-chip.v-chip {
  color: $color-white !important;
  border: none !important;
  background-color: rgba($color: $color-iframe, $alpha: 1) !important;
}

// Splash Screen
.splash__screen {
  .v-overlay__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100vh;
    width: 100%;
  }

  & .media-display {
    width: 100%;
    padding: 1rem;
  }

  &-btn {
    position: absolute;
    bottom: 5rem;
  }

  .ra__media-display {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }
}

.btn-name {
  color: $color-dark !important;
}

.attendees{
  &__search{
    display: block;
    width: 100%;
    padding: 1.5rem;

    &-box{
      width: 100%;
      display: block;
      margin-bottom: 0.5rem;
    }
    &-list{
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respondMax(sm){
        flex-flow: column;
        align-items: flex-start;
      }
    }
    &-detail{
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      @include respondMax(sm){
        margin-bottom: 1rem;
      }
      &--head {
        color: var(--ra-color-dark);
        margin-right: 1rem;
        white-space: nowrap;
        text-transform: capitalize;
        @extend .fw-600;
      }
      &--p1 {
        color: var(--ra-color-dark);
        margin-bottom: 0 !important;
        margin-right: 1rem;
        white-space: nowrap;
        text-transform: capitalize;
        @extend .fw-600;
      }
      &--p2 {
        color: var(--ra-color-dark);
        margin-bottom: 0 !important;
        white-space: nowrap;
      }
    }
    &-dropdown-search {
      flex-flow: row !important;
      flex: unset !important;
      .v-list-item__title {
        overflow: unset !important;
        margin-right: 1rem;
      }
      .v-list-item__subtitle {
        span {
          text-transform: capitalize;
          @extend .fw-600;
        }
      }
    }
  }
}

.top-zero-imp{
  top: 0 !important;
}

.externalPagePad{
  padding: 0 !important;
}

// Public Event
.publicmodal {
  &__sticky {
    position: sticky;
    top: 68px;
    left: 0;
    z-index: 5;
    background-color: var(--ra-lobby-schedule_container_colour);
    backdrop-filter: blur(10px);
    @include respondMin(md) {
      top: 80px;
    }
    &-back {
      color: var(--ra-lobby-main_font_colour) !important;
    }
  }
  &__card {
    margin-bottom: 0.5rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: var(--ra-lobby-schedule_container_colour);
    &-wrapper {
      max-height: 450px;
      overflow: auto;
    }
    &-data {
      margin-bottom: 0.5rem !important;
      color: var(--ra-lobby-main_font_colour);
      &--dark {
        color: var(--ra-lobby-main_font_colour);
        @extend .fw-500;
        margin-bottom: 0.5rem !important;
      }
    }
    &-select {
      width: 100px;
      .v-text-field__details {
        display: none !important;
      }
    }
    &-summary {
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: var(--ra-lobby-schedule_container_colour);

      @include respondMin(md) {
        position: sticky;
        top: 100px;
      }

      &--sticky {
        position: sticky;
        bottom: 0;
      }

      &:hover {
        box-shadow: 0px 4px 13px rgb(0, 0, 0, 0.2) !important;
      }

      hr {
        border-color: var(--ra-lobby-main_font_colour) !important;
      }
    }
  }
  &__table {
    overflow: auto;
    margin: 0.5rem 0 1rem;
    color: var(--ra-color-dark);
  }
}

// External Page
.fl-row-content-wrap {
  margin-top: 60px !important;
}
.reattendancePage {
  height: 86vh !important;
}

// Timeline
// .e-schedule .e-timeline-view .e-event-table { 
//   .e-appointment-container { 
    .schedule-vue-sample .schedule-cell-dimension.e-schedule .e-timeline-view .e-appointment, .e-schedule .e-timeline-view .e-appointment {
      &:nth-child(1) {
        background-color: $color-offgreen !important;
      }
      &:nth-child(2) {
        background-color: $color-offlightgreen !important;
      }
      &:nth-child(3) {
        background-color: $color-offlightorange !important;
      }
      &:nth-child(4) {
        background-color: $color-offorange !important;
      }  
      &:nth-child(5) {
        background-color: $color-offpink !important;
      }
      &:nth-child(6) {
        background-color: $color-iframe !important;
      }
      &:nth-child(7) {
        background-color: $color-html !important;
      }
      &:nth-child(8) {
        background-color: $color-prv !important;
      }  
      &:nth-child(9) {
        background-color: $color-rtmp !important;
      }
      &:nth-child(10) {
        background-color: $color-rs !important;
      }  
    }
//   }  
// }

.e-schedule-toolbar-container {
  padding: 0 12px;
}