.pricing {
  margin-top: 1rem;
  height: calc(100vh - 80px);
  overflow: auto;
  &__thead{
    &-title{
      display: flex;
      justify-content: center;
      align-items: center;
      white-space: nowrap !important;
    }
    &-info{
      margin-left: 1rem;
      width: 24px;
      height: 24px;
    }
  }
  & table {
    border-collapse: separate;
    width: 100%;
    border-spacing: 0px 8px;

    & td, th{
      text-align: center;
      padding: 1rem;
    }

    & th, .tagline {
      color: var(--ra-color-dark);
      font-size: 1rem;
      @extend .fw-500;
    }
    & thead{
      position: sticky;
      top: -8px;
      z-index: 5;

      th{
        font-size: 1.25rem;
        @extend .fw-600;
        color: var(--ra-color-dark);
        position: relative;
        background-color: var(--ra-color-white);
        color: var(--ra-color-dark);

        @include respondMax(md){
          padding: 0 0.25rem;
          font-size: 1rem;
        }

        &:first-child{
          left: -1px; // for avoid text overlay
          z-index: 6;
          padding-left: 1rem;
          position: sticky;
          .pricing{
            &__thead{
              &-title{
              justify-content: space-between !important;
              }
              &-info{
                margin: 0 1rem;
              }
            }
          }
        }
        &:not(:first-child){
          padding: 0.5rem;
        }
        &:last-child{
          .pricing__info-popup{
            left: inherit;
            right: 20px;
          }
        }
        .pricing__info-popup{
          z-index: 9;
        }
      }
    }

    & tbody {
      position: relative;
      z-index: 1;
      
      tr {
        position: relative;
        &:nth-child(1){
          position: sticky;
          top: 86px;
          width: 100%;
          z-index: 6;
        }
        &:nth-child(2){
          position: sticky;
          top: 140px;
          width: 100%;
          z-index: 5; 
          box-shadow: 0 5px 12px rgba(0,0,0,0.08);
        }
        &:nth-of-type(odd) {
          background-color: var(--ra-color-offwhite2);
        }
        &:nth-of-type(even) {
          background-color: var(--ra-color-white);
        }
        th {
          position: sticky;
          left: -1px; // for avoid text overlay
          z-index: 3;
          padding-left: 1rem;
          background-color: var(--ra-color-white);
          color: var(--ra-color-dark);

          .v-icon.header-th{
            color: $color-white;
            float: right;
            right: 25px;
          }
        }
      }
    }

  }
  // &__section-head{
  // background-color: var(--ra-color-offgreen) !important;
  // color: $color-white !important;
  // }
  &__yes {
    color: $color-primary !important;
  }

  &__no {
    color: $color-red !important;
  }
  &__head-title{
    display: flex;
    justify-content: space-between;
  }
  &__info{
    margin: 0 1rem;
    width: 24px;
    height: 24px;
  }
  &__info {
    &-popup {
      background-color: var(--ra-color-white);
      position: absolute;
      top: 60px;
      left: 20px;
      border-radius: 1rem !important;
      padding: 0.5rem 1rem;
      box-shadow: 0px 0px 16px 4px rgb(0 0 0 / 20%) !important;
      max-width: 350px;
      min-width: 350px;
      z-index: 9;
      line-height: normal;
      
      @include respondMax(lg){
        left: 0;
        right: auto;
        max-width: 300px;
        min-width: 300px;
      
        &::before{
          display: none;
        }
      }
      @include respondMax(md){
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .v-list{
        background-color: transparent !important
        ;
      }
      .v-btn > .v-btn__content .v-icon {
        color: inherit !important;
      }
  
      .theme--dark.v-list{
        background-color: transparent;
      }
    }
    &-title {
      @extend .fw-600;
      text-align: left;
      font-size: 1.2rem;
      margin-bottom: 0;
      display: flex;
      align-items: center;
    }
    &-sublist {
      color: var(--ra-color-dark);
      margin-bottom: 0.5rem;
      @extend .fw-400;
    }
    &-desc {
      margin-bottom: 0.5rem;
      white-space: normal;
      @extend .fw-400;
    }
    &-block {
      text-align: left;
      letter-spacing: normal !important;
      margin-top: 0.5rem;
      span {
        font-size: 0.9rem;
        text-align: left;
        line-height: 1.2rem;
      }
    }
  }
}

.parent {
  .pricing-group-head{
    .v-icon{
      transition: transform 0.4s ease-in-out;
    }
  }
  &.active{
    .pricing-group-head{
      .v-icon{
        transform: rotate(0deg);
      }
    }
  }
  .pricing-group-head{
    .v-icon{
      transform: rotate(180deg);
    }
  }
  .pricing__section-head{
    border-radius: 0.5rem;
  }
  &-title {
    &-0 {
      .pricing__section-head{
        background-color: $color-offgreen;
        color: $color-white;
      }
    }

    &-1 {
      .pricing__section-head{
        background-color: $color-offlightgreen;
        color: $color-white;
      }
    }

    &-2 {
      .pricing__section-head{
        background-color: $color-offlightorange;
        color: $color-white;
      }
    }

    &-3 {
      .pricing__section-head{
      background-color: $color-offorange;
      color: $color-white;
      }
    }

    &-4 {
      .pricing__section-head{
        background-color: $color-offpink;
        color: $color-white;
      }
    }

    &-5 {
      .pricing__section-head{
        background-color: $color-iframe;
        color: $color-white;
      }
    }

    &-6 {
      .pricing__section-head{
        background-color: $color-html;
        color: $color-white;
      }
    }
    &-7 {
      .pricing__section-head{
        background-color: $color-red;
        color: $color-white;
      }
    }

    &-8 {
      .pricing__section-head{
        background-color: $color-primary;
        color: $color-white;
      }
    }

    &-9 {
      .pricing__section-head{
        background-color: $color-prv;
        color: $color-white;
      }
    }
  }
}
// tr.child{
//   display: none;
// }
.pricing {
  a.btn-primary.v-size--default.btn-success{
    background-color: var(--ra-color-green2) !important;
    color: $color-white !important;
  }
  button.btn-primary{
    text-transform: uppercase !important;
  }
  button.btn-primary.v-size--default.btn-success{
    background-color: var(--ra-color-green2) !important;
    color: $color-white !important;
  }

  &__mobile {
    &-block {
      border-radius: 0.5rem;
      padding: 1rem;
      background-color: var(--ra-color-offwhite2) !important;
      margin-bottom: 1rem;
    }
    &-title {
      font-size: 1.5rem;
      color: var(--ra-color-dark);
      @extend .fw-500;
    }
    &-subtitle {
      color: var(--ra-color-grey);
      margin-bottom: 0.25rem !important;
    }
    &-price {
      margin-top: 1rem;
      &--data {
        font-size: 1.125rem;
        color: var(--ra-color-dark);
        @extend .fw-500;
      }
    }
  }
  &__accordion-title {
    font-size: 1rem;
    color: var(--ra-color-grey);
    margin-bottom: 0.5rem !important;
    padding-right: 0.5rem;
  }
  &__accordion-data {
    font-size: 1rem;
    color: var(--ra-color-dark);
    @extend .fw-500;
    margin-bottom: 0.5rem !important;
  }
  &__info-mobile {
    font-size: 1.25rem !important;
  }
}
.ra {
  &__downgrade-popup {
    max-height: 300px;
    overflow: auto;
    .ra__downgrade-box {
      margin-bottom: 1rem;
    }
  }
}