.sales {
    &__title {
        color: var(--ra-color-dark);
        @extend .fw-500;
        margin: 1.5rem 0 1rem;
    }
    &__card {
        background-color: var(--ra-color-offwhite);
        padding: 1rem;
        border-radius: 0.5rem;
        text-align: center;
        margin-bottom: 1rem;
        @include respondMin(lg){
            height: 100%;
        }
        &-title {
            @extend .fw-600;
        }
    }
}

.popup__tooltip {
    border-radius: 1rem !important;
    padding-top: 0.5rem;
    &-title {
        font-size: 1.2rem;
        margin-bottom: 0; 
        @extend .fw-600;
    }
}

.sales {
    &__modal {
        padding-bottom: 1rem;
        &-title {
            font-size: 1.125rem;
            @extend .fw-600;
            color: var(--ra-color-dark);
            margin: 0.75rem 0;
        }
        &-input {
            .v-text-field__details {
                display: none;
            }
            .v-input__slot {
                margin-bottom: 1rem !important;
            }
        }
    }
}