.side {
  // sideMenu Main Wrapper
  &__wrapper {
    display: none;
    padding: 0 !important;
    border-right: 1px solid var(--ra-color-bggrey);
    @include respondMin(md) {
      display: block !important;
    }
  } 

  // middel Panel side-menu
  &__panel {
    @include respondMin(md) {
      position: sticky;
      top: 70px;
      display: grid;
      grid-template-rows: auto 1fr;
      height: calc(100vh - 70px);
      overflow: hidden;
    }
    text-align: left;
    overflow: auto;
    height: 100%;
    &-block {
      border-bottom: 1px solid var(--ra-color-offwhite);
      padding: 1rem;
    }
    &-menus {
      width: 100% !important;
      @extend .ra__blurbg-1;
      &--items {
        &.v-list-item__title {
          white-space: normal;
        }
      }
    }
    &-color {
      color: var(--ra-color-primary) !important;
      caret-color: var(--ra-color-primary) !important; 
    }
    & .v {
      &-navigation-drawer {
        &__content {
          & .v-list {
            height: 100%;
          }
        }
      }
      &-list {
        &-group {
          &__header {
            padding: 0 !important;
            &__append-icon {
              position: absolute;
              right: 4px;
            }
          }
          &__items {
            & .v-list {
              &-item {
                padding-left: 1.5rem !important;
                &--active {
                  &::before {
                    background: none !important;
                  }
                }
              }
            }
          }
        }
        &-item {
          &__title {
            font-size: 0.95rem !important;
            opacity: 0.65;
            padding-right: 1rem !important;
          }
          &--active {
            & .side__panel-menus--items {
              color: var(--ra-color-dark) !important;
              @extend .fw-600;
              opacity: 1;
            }
          }
        }
      }
    }
    &-title {
      font-size: 1.25rem;
      @extend .fw-600;
      color: var(--ra-color-dark);
    }
    &-back {
      font-size: 0.95rem;
      color: $color-white !important;
      background-color: var(--ra-color-primary);
      padding: 0.25rem 1rem 0.25rem 0.2rem;
      border-radius: 2rem;
      margin-bottom: 0.75rem;
      display: inline-block;
      &--icon {
        &.v-icon {
          color: $color-white !important;
        }
      }
    }
  }

  // left Panel Side-menu
  &__drawer {
    z-index: 101 !important;
    background-color: var(--ra-color-offwhite) !important;
    
    &-icon {
      width: 32px !important;
      height: 32px !important;
      min-width: 32px !important;
    } 
    .v-list--nav{
      padding-left: 7px !important;
      padding-right: 6px !important;
    }
    &-menu {
      &--items {
        padding: 0 !important; 
        
        & .v-list-item__title {
          font-size: 0.875rem !important;
        }
      }
      &--myaccount{
        border: none;
        padding: 0 4px !important; 
        margin-left: 14px;
      }
      .v-list-item__title{
        color: var(--ra-color-dark) !important;
      }
      &--items.v-item--active{
        background-color: rgb(21 18 18 / 20%) !important;
        .v-list-item__icon{
          border-color: transparent !important;
        }
      }
      &--profile {
        padding: 0 !important;
        & .v-avatar {
          width: 40px !important;
          height: 40px !important;
          min-width: 40px !important; 
          margin-left: 0px !important;
        }
        & .v-list-item__title {
          font-size: 0.875rem !important;
        }
      }
    }
    &-color {
      color: var(--ra-color-primary) !important;
      caret-color: var(--ra-color-primary) !important;
    }
    &-menu--items.v-list-item--active{
      &::before{
        opacity: 0.2 !important;
      }
    }
    .v-list-item__icon {
      height: 32px !important; 
      max-width: 42px !important;
      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--ra-color-offgrey);
      border-radius: 50%;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}

.sidebar-linktitile {
  padding-left: 10px !important;
}

.v-item--active .v-list-item__icon{
  border-color: transparent !important;
}